import React from 'react';
import { Link } from 'react-router-dom';
import { FOOTER_CONFIG } from './footerConfig';

const Footer = ({ className = "", overrides = {} }) => {
  const config = { ...FOOTER_CONFIG, ...overrides };

  return (
    <footer className={`bg-black text-white py-8 ${className}`}>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-xl font-bold mb-4">{config.companyInfo.name}</h3>
            <p>{config.companyInfo.slogan}</p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {config.quickLinks.map((link, index) => (
                <li key={index}>
                  <Link to={link.path} className="hover:text-gray-300 transition-colors duration-200">
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              {config.legal.map((link, index) => (
                <li key={index}>
                  <Link to={link.path} className="hover:text-gray-300 transition-colors duration-200">
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4">
            <h4 className="text-lg font-semibold mb-4">Connect With Us</h4>
            <div className="flex space-x-4">
              {config.socialMedia.map((platform, index) => (
                <a
                  key={index}
                  href={platform.url}
                  className="hover:text-gray-300 transition-colors duration-200"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">{platform.platform}</span>
                  <i className={`fab fa-${platform.platform.toLowerCase()}`}></i>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>{config.copyright}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;