import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { Button } from '../ui/button';
import { ChevronLeft, RotateCcw, Book, Check, X, Edit, Trash2 } from 'lucide-react';
import Footer from '../landing/Footer';
import * as api from '../../services/api';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import CustomMarkdown from '../ui/custom-markdown';

const DeckStudyScreen = ({ deck, topic, onBack, onStudyComplete }) => {
    const [cardsToStudy, setCardsToStudy] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const [studyHistory, setStudyHistory] = useState([]);
    const [statistics, setStatistics] = useState({ total: 0 });
    const [isStudyComplete, setIsStudyComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFreeReview, setIsFreeReview] = useState(false);
    const [freeReviewCount, setFreeReviewCount] = useState(0);
    const [editingCard, setEditingCard] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [cardPositions, setCardPositions] = useState([]);

    const answerQueue = useRef([]);
    const processingQueue = useRef(false);
    const lastConfirmedIndex = useRef(-1);

    const fetchCardsToStudy = useCallback(async () => {
    console.log('fetchCardsToStudy called with deck:', deck, 'and topic:', topic);
    console.log('Current isFreeReview state:', isFreeReview);

    if (!deck && !topic) {
        console.error('Both deck and topic are undefined in fetchCardsToStudy');
        return;
    }

    setIsLoading(true);

    try {
        let response;
        if (!topic) {
            console.log('Topic is null. Fetching cards to study from deck ', deck.id);
            if (isFreeReview) {
                console.log('Calling getRandomCards for deck ', deck.id);
                response = await api.getRandomCards(deck.id, 'deck');
            } else {
                console.log('Calling getCardsToStudy for deck ', deck.id);
                response = await api.getCardsToStudy(deck.id, 'deck');
            }
        } else {
            console.log('Topic is not null. Fetching cards to study from topic ', topic.id);
            if (isFreeReview) {
                console.log('Calling getRandomCards for topic ', topic.id);
                response = await api.getRandomCards(topic.id, 'topic');
            } else {
                console.log('Calling getCardsToStudy for topic ', topic.id);
                response = await api.getCardsToStudy(topic.id, 'topic')
            }
        }
        console.log(`response received: ${response.data}`)
        setCardsToStudy(response.data);
        setCurrentCardIndex(0);
        setShowAnswer(false);
        setStudyHistory([]);
        setStatistics({ total: 0 });
        setIsStudyComplete(response.data.length === 0);
    } catch (error) {
        console.error(`Error in fetchCardsToStudy:`, error);
        if (error.response) {
            console.error('Error response:', error.response);
        }
    } finally {
        setIsLoading(false);
    }
    }, [deck, topic, isFreeReview]);
    
    const processAnswerQueue = useCallback(async () => {
        if (processingQueue.current) return;
        processingQueue.current = true;

        while (answerQueue.current.length > 0) {
            const { cardId, difficulty } = answerQueue.current[0];
            try {
                await api.reviewCard(cardId, difficulty);
                answerQueue.current.shift();
                lastConfirmedIndex.current++;
            } catch (error) {
                console.error('Error processing answer:', error);
                break;
            }
        }

        processingQueue.current = false;
    }, []);

    const startFreeReview = () => {
        setIsFreeReview(true);
        setFreeReviewCount(prev => prev + 1);
        fetchCardsToStudy();
    };

    useEffect(() => {
        fetchCardsToStudy();
    }, [fetchCardsToStudy]);

    useEffect(() => {
        const interval = setInterval(processAnswerQueue, 1000);
        return () => clearInterval(interval);
    }, [processAnswerQueue]);

    const currentCard = cardsToStudy[currentCardIndex];

    const toggleAnswer = () => {
        setShowAnswer(true);
    };

    const handleRecordAnswer = async (difficulty) => {
        if (!currentCard) return;

        let positionInfo = null;
        let newCards = [...cardsToStudy];

        // Handle requeuing for HARD cards
        if (difficulty === 'HARD') {
            const originalPosition = currentCardIndex;
            
            // Remove the card from its current position
            newCards.splice(currentCardIndex, 1);
            
            // Calculate a random position 3-8 cards later
            const minPosition = Math.min(currentCardIndex + 3, newCards.length);
            const maxPosition = Math.min(currentCardIndex + 8, newCards.length);
            const newPosition = Math.floor(Math.random() * (maxPosition - minPosition + 1)) + minPosition;
            
            // Insert the card at the new position
            newCards.splice(newPosition, 0, currentCard);
            
            positionInfo = {
                originalPosition,
                newPosition,
                card: currentCard
            };
            
            setCardsToStudy(newCards);
            setCardPositions(prev => [...prev, positionInfo]);
        }

        // Record in study history with position information
        setStudyHistory(prev => [...prev, { 
            card: currentCard, 
            difficulty, 
            date: new Date(),
            positionInfo 
        }]);

        // Rest of the existing statistics update
        setStatistics(prev => ({
            total: prev.total + 1
        }));

        // Queue the answer
        answerQueue.current.push({ cardId: currentCard.id, difficulty });

        // Move to next card or complete study
        if (currentCardIndex < cardsToStudy.length - 1) {
            setCurrentCardIndex(prev => prev + 1);
            setShowAnswer(false);
        } else {
            setIsStudyComplete(true);
        }

        // Trigger queue processing
        processAnswerQueue();
    };

    const handleCompleteStudy = (deck) => {
        console.log('Calling onStudyComplete with deck ', deck )
        onStudyComplete(deck.id);
        onBack(deck.id);
    };

    const handleUndo = () => {
        if (studyHistory.length === 0) return;

        const lastEntry = studyHistory[studyHistory.length - 1];
        
        // Remove the last entry from history
        setStudyHistory(prev => prev.slice(0, -1));
        
        // Update statistics
        setStatistics(prev => ({
            total: prev.total - 1
        }));

        // If it was a HARD card, restore its original position
        if (lastEntry.positionInfo) {
            const newCards = [...cardsToStudy];
            const { originalPosition, newPosition, card } = lastEntry.positionInfo;
            
            // Remove from requeued position
            newCards.splice(newPosition, 1);
            // Restore to original position
            newCards.splice(originalPosition, 0, card);
            
            setCardsToStudy(newCards);
            setCardPositions(prev => prev.slice(0, -1));
            setCurrentCardIndex(originalPosition);
        } else {
            // Normal undo behavior for non-HARD cards
            setCurrentCardIndex(prev => prev - 1);
        }

        setShowAnswer(false);
    };

    const handleEditCard = async (cardId, updatedCard) => {
        try {
            await api.updateCard(cardId, updatedCard.question, updatedCard.answer);
            setCardsToStudy(cards => cards.map(card => 
                card.id === cardId ? {...card, ...updatedCard} : card
            ));
            setEditingCard(null);
            setIsEditDialogOpen(false);
        } catch (error) {
            console.error('Error updating card:', error);
        }
    };

    const handleDeleteCard = async (cardId) => {
        try {
            await api.deleteCard(cardId);
            setCardsToStudy(cards => cards.filter(card => card.id !== cardId));
            setEditingCard(null);
            setIsEditDialogOpen(false);
            
            // If this was the last card, mark study as complete
            if (cardsToStudy.length <= 1) {
                setIsStudyComplete(true);
            } else if (currentCardIndex >= cardsToStudy.length - 1) {
                // If we deleted the last card in the list, move to previous card
                setCurrentCardIndex(currentCardIndex - 1);
            }
        } catch (error) {
            console.error('Error deleting card:', error);
        }
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <Card className="w-full max-w-2xl bg-white shadow-md border border-gray-200">
                    <CardContent className="flex justify-center items-center h-48 sm:h-64">
                        <p>Loading...</p>
                    </CardContent>
                </Card>
            );
        }

        if (isStudyComplete || cardsToStudy.length === 0) {
            return (
                <Card className="w-full max-w-2xl bg-white shadow-md border border-gray-200">
                    <CardHeader className="space-y-2">
                        <CardTitle className="text-xl sm:text-2xl font-bold break-words">
                            {isStudyComplete ? "Study session complete!" : "No cards to study"}
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <p className="text-sm sm:text-base break-words">
                            {isStudyComplete 
                                ? `You've completed your ${isFreeReview ? 'free review' : 'regular study'} session.` 
                                : "There are no cards due for study at this time."}
                        </p>
                        {isStudyComplete && !isFreeReview && (
                            <p className="text-sm sm:text-base">
                                Total cards studied: {statistics.total}
                            </p>
                        )}
                        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
                            <Button 
                                onClick={startFreeReview} 
                                className="w-full sm:w-auto bg-snapcards-blue text-white hover:bg-blue-700"
                            >
                                {freeReviewCount > 0 ? "Continue Free Review" : "Start Free Review"}
                            </Button>
                            <Button 
                                onClick={() => handleCompleteStudy(deck)}
                                className="w-full sm:w-auto bg-gray-500 text-white hover:bg-gray-700"
                            >
                                Back to Main
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            );
        }

        return (
            <div className="w-full max-w-2xl space-y-4">
                <Card className="bg-white shadow-md border border-gray-200 relative">
                    <CardHeader className="space-y-2">
                        <CardTitle className="text-xl sm:text-2xl font-bold break-words flex flex-wrap items-center gap-2">
                            Study: {topic ? topic.name : deck.name}
                            {!topic && currentCard?.topicName && (
                                <span className="text-xs bg-snapcards-blue text-white px-1.5 py-0.5 rounded-md translate-y-[3px] inline-flex items-center">
                                    {currentCard.topicName}
                                </span>
                            )}
                        </CardTitle>
                        <CardDescription className="text-sm flex flex-col sm:flex-row sm:gap-2">
                            <span>Progress: {currentCardIndex + 1} / {cardsToStudy.length}</span>
                        </CardDescription>
                    </CardHeader>
                    {currentCard && (
                        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
                            <DialogTrigger asChild>
                                <Button 
                                    variant="outline" 
                                    size="icon" 
                                    className="absolute top-4 right-4"
                                    onClick={() => {
                                        setEditingCard(currentCard);
                                        setIsEditDialogOpen(true);
                                    }}
                                >
                                    <Edit className="h-4 w-4" />
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="bg-white p-4 rounded-lg">
                                <DialogHeader>
                                    <DialogTitle className="text-xl font-bold mb-4">Edit Card</DialogTitle>
                                    <p className="text-sm text-gray-500">Markdown formatting is supported</p>
                                </DialogHeader>
                                {editingCard && (
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleEditCard(editingCard.id, {
                                            question: e.target.question.value,
                                            answer: e.target.answer.value
                                        });
                                    }}>
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium">Question</label>
                                            <Input
                                                name="question"
                                                defaultValue={editingCard.question}
                                                placeholder="Question (supports markdown)"
                                                className="mb-2 w-full font-mono"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium">Answer</label>
                                            <Textarea
                                                name="answer"
                                                defaultValue={editingCard.answer}
                                                placeholder="Answer (supports markdown)"
                                                className="mb-4 w-full font-mono"
                                            />
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <Button 
                                                type="button"
                                                variant="outline"
                                                size="icon"
                                                className="text-red-500 hover:text-red-700"
                                                onClick={() => handleDeleteCard(editingCard.id)}
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                            <div className="flex space-x-2">
                                                <Button type="button" variant="outline" onClick={() => setIsEditDialogOpen(false)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" className="bg-snapcards-blue text-white hover:bg-blue-700">
                                                    Save Changes
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </DialogContent>
                        </Dialog>
                    )}
                    <CardContent className="min-h-[16rem] flex flex-col justify-between">
                        <div className="flex-grow flex items-center justify-center p-4 sm:p-6">
                            <div className="text-lg sm:text-xl text-center break-words overflow-auto max-h-48 sm:max-h-64">
                                <CustomMarkdown>
                                    {currentCard && (showAnswer ? currentCard.answer : currentCard.question)}
                                </CustomMarkdown>
                            </div>
                        </div>
                        <div className="flex justify-center p-4 bg-gray-50 rounded-b-lg">
                            {!showAnswer ? (
                                <Button 
                                    onClick={toggleAnswer} 
                                    className="w-full sm:w-auto bg-snapcards-blue text-white hover:bg-blue-700"
                                >
                                    Show Answer
                                </Button>
                            ) : (
                                <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-2 sm:gap-4">
                                    <Button 
                                        onClick={() => handleRecordAnswer('EASY')} 
                                        variant="outline" 
                                        className="w-full sm:w-auto bg-green-100 hover:bg-green-200"
                                    >
                                        <Check className="h-4 w-4 mr-2 text-green-500" /> Easy
                                    </Button>
                                    <Button 
                                        onClick={() => handleRecordAnswer('MEDIUM')} 
                                        variant="outline" 
                                        className="w-full sm:w-auto bg-yellow-100 hover:bg-yellow-200"
                                    >
                                        <Book className="h-4 w-4 mr-2 text-yellow-500" /> Medium
                                    </Button>
                                    <Button 
                                        onClick={() => handleRecordAnswer('HARD')} 
                                        variant="outline" 
                                        className="w-full sm:w-auto bg-red-100 hover:bg-red-200"
                                    >
                                        <X className="h-4 w-4 mr-2 text-red-500" /> Hard
                                    </Button>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>

                <Card className="bg-white shadow-md border border-gray-200">
                    <CardContent className="p-4">
                        <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4">
                            <Button 
                                onClick={() => handleCompleteStudy(deck)} 
                                className="w-full sm:w-auto bg-snapcards-blue text-white hover:bg-blue-700"
                            >
                                <ChevronLeft className="h-4 w-4 mr-2" /> Back to Main
                            </Button>
                            <Button 
                                onClick={handleUndo} 
                                disabled={studyHistory.length === 0} 
                                className="w-full sm:w-auto bg-gray-200 text-gray-800 hover:bg-gray-300"
                            >
                                <RotateCcw className="h-4 w-4 mr-2" /> Undo Last Answer
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-snapcards-cream text-black font-segoe">
            <main className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex flex-col items-center justify-center">
                    {renderContent()}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default DeckStudyScreen;