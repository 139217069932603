import { HEADER_CONFIG } from './headerConfig';

export const PRICING_PAGE_CONTENT = {
  header: HEADER_CONFIG,
  hero: {
    title: "Simple Credit-Based Pricing",
    description: "Pay only for what you use. No subscriptions, no commitments."
  },
  creditPacks: [
    {
      amount: 200,
      price: 4,
      perCard: 0.02,
      label: "Starter"
    },
    {
      amount: 500,
      price: 8,
      perCard: 0.016,
      label: "Standard"
    },
    {
      amount: 1000,
      price: 12,
      perCard: 0.012,
      label: "Pro"
    }
  ],
  features: [
    "AI-powered flashcard generation",
    "Web and mobile access",
    "Unlimited manual card creation",
    "Deck import and export",
    "Collaborative study groups",
    "Performance analytics"
  ],
  additionalInfo: {
    title: "Why Credits?",
    items: [
      {
        title: "Flexibility",
        description: "Buy credits as needed. No recurring charges."
      },
      {
        title: "Permanent",
        description: "Credits don't expire. Use them at your own pace."
      },
      {
        title: "Future-Ready",
        description: "Credits will be applicable to new features as we develop them."
      },
      {
        title: "Cost-Effective",
        description: "Larger packs offer better value per AI-generated card."
      }
    ]
  },
  faq: {
    title: "FAQ",
    items: [
      {
        question: "What do credits do?",
        answer: "One credit generates one AI-powered flashcard. Manual card creation is always free."
      },
      {
        question: "Do credits expire?",
        answer: "No. Your purchased credits remain valid indefinitely."
      },
      {
        question: "Can I use Snapcards without credits?",
        answer: "Yes. You can create and manage flashcards manually at no cost. Credits are only for AI-generated cards."
      },
      {
        question: "How will credits evolve?",
        answer: "As we develop new AI features, your existing credits will be compatible, potentially increasing in utility."
      }
    ]
  },
  cta: {
    title: "Start Learning Efficiently",
    description: "Create AI-powered flashcards and optimize your study process.",
    button: {
      label: "Get Started",
      path: "/register",
    },
  },
  footer: {
    companyInfo: {
      name: "Snapcards",
      slogan: "Innovating the Future of Learning"
    },
    quickLinks: [
      { label: "About Us", path: "/about" },
      { label: "Research", path: "/research" },
      { label: "Pricing", path: "/pricing" },
      { label: "Contact", path: "/contact" }
    ],
    legal: [
      { label: "Terms of Service", path: "/terms" },
      { label: "Privacy Policy", path: "/privacy" }
    ],
    socialMedia: [
      { platform: "Facebook", url: "#" },
      { platform: "Twitter", url: "#" },
      { platform: "Instagram", url: "#" },
      { platform: "LinkedIn", url: "#" }
    ],
    copyright: "© 2024 Snapcards. All rights reserved."
  }
};