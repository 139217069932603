import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

// Helper function to process text with LaTeX
const processLatexInText = (text) => {
    if (typeof text !== 'string') return text;
    
    // Handle code blocks that might be inline with text
    const codeBlockRegex = /(.*?)```latex\s*([\s\S]*?)```(.*?)(?:\n|$)([\s\S]*)/;
    const codeMatch = text.match(codeBlockRegex);
    
    if (codeMatch) {
        const [_, beforeCode, latexCode, afterCodeSameLine, remainingText] = codeMatch;
        const latex = latexCode.trim();
        
        // Match exactly how the code component handles LaTeX
        const processedLatex = (
            <div className="prose">
                <div className="text-inherit">
                    <BlockMath math={latex.replace(/\\\\([^\\])/g, '\\$1')} style={{ color: 'inherit' }} />
                </div>
            </div>
        );
        
        return [
            processLatexInText(beforeCode),
            processedLatex,
            afterCodeSameLine,
            processLatexInText(remainingText)
        ].filter(Boolean);
    }
    
    // Handle inline LaTeX
    const parts = text.split(/(\$[^$]+\$)/g);
    return parts.map((part, i) => {
        if (part.startsWith('$') && part.endsWith('$')) {
            const math = part.slice(1, -1)
                .replace(/\\\\([^\\])/g, '\\$1');
            try {
                return (
                    <span className="text-black" key={i}>
                        <InlineMath math={math} style={{ color: 'currentColor' }} />
                    </span>
                );
            } catch (e) {
                console.error('LaTeX parsing error:', e);
                return <code key={i} className="text-red-500">LaTeX Error: {math}</code>;
            }
        }
        return part;
    });
};

const CustomMarkdown = ({ children }) => {
    const components = {
        p: ({ children }) => {
            if (Array.isArray(children)) {
                // Process each child in the array
                const processed = children.map((child, i) => {
                    // If it's a string containing a newline, split and process separately
                    if (typeof child === 'string' && child.includes('\n')) {
                        const [first, ...rest] = child.split('\n');
                        return [
                            first,
                            ...rest.map(text => (
                                <p key={`p-${i}-${text}`}>
                                    {processLatexInText(text)}
                                </p>
                            ))
                        ];
                    }
                    return child;
                }).flat();

                return <p>{processed}</p>;
            }
            return <p>{processLatexInText(children)}</p>;
        },

        li: ({ children }) => {
            if (typeof children === 'string') {
                return <li>{processLatexInText(children)}</li>;
            }
            // Handle nested elements within list items
            return <li>{React.Children.map(children, child => 
                typeof child === 'string' ? processLatexInText(child) : child
            )}</li>;
        },

        code({ node, inline, className, children, ...props }) {
            // For inline code
            if (inline) {
                return (
                    <code className="bg-gray-800 text-gray-200 px-1.5 py-0.5 rounded text-sm" {...props}>
                        {children}
                    </code>
                );
            }

            // For code blocks
            const match = /language-(\w+)/.exec(className || '');
            const language = match ? match[1] : '';

            // Handle LaTeX blocks separately
            if (language === 'latex' || (typeof children === 'string' && children.trim().startsWith('latex'))) {
                const value = String(children).replace(/^latex\s*/, '').trim();
                return (
                    <div className="text-inherit">
                        <BlockMath math={value} style={{ color: 'inherit' }} />
                    </div>
                );
            }

            // Regular code blocks
            return (
                <div className="not-prose my-4">
                    <SyntaxHighlighter
                        style={{
                            ...vscDarkPlus,
                            'pre[class*="language-"]': {
                                ...vscDarkPlus['pre[class*="language-"]'],
                                background: '#1E1E1E',
                                padding: '0',
                            },
                            'code[class*="language-"]': {
                                ...vscDarkPlus['code[class*="language-"]'],
                                background: '#1E1E1E',
                                padding: '0',
                            },
                            'token': {
                                ...vscDarkPlus['token'],
                                display: 'inline',
                                marginLeft: '0',
                                paddingLeft: '0',
                            }
                        }}
                        language={language}
                        PreTag="div"
                        customStyle={{
                            margin: '0',
                            padding: '1rem',
                            borderRadius: '0.5rem',
                            background: '#1E1E1E',
                            fontSize: '0.9rem',
                            lineHeight: '1.5'
                        }}
                        showLineNumbers={false}
                        {...props}
                    >
                        {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                </div>
            );
        }
    };

    console.log("CustomMarkdown received:", children);

    return (
        <ReactMarkdown components={components}>
            {children}
        </ReactMarkdown>
    );
};

export default CustomMarkdown; 