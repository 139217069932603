import { HEADER_CONFIG } from './headerConfig';

export const TERMS_PAGE_CONTENT = {
  header: HEADER_CONFIG,
  termsAndPolicy: {
    title: "Snapcards Terms of Use and Privacy Policy",
    lastUpdated: "Last Updated: 18/09/2024",
    sections: [
      {
        title: "1. Introduction",
        content: (
          <>
            <p>Welcome to Snapcards ("I," "my," or "me"). This document outlines the Terms of Use and Privacy Policy, which govern your use of the Snapcards website and mobile application (collectively, the "Service"). Snapcards is operated by Yonathan Peles, an individual based in Israel, and operates globally.</p>
            <p>By accessing or using the Service, you agree to be bound by these terms. If you disagree with any part of the terms, you may not use the Service.</p>
          </>
        )
      },
      {
        title: "2. Privacy Policy",
        subsections: [
          {
            title: "2.1 Information We Collect",
            content: (
              <>
                <p>a. Personal Information:</p>
                <ul>
                  <li>Email address (required for account creation)</li>
                  <li>Name (if provided)</li>
                  <li>Any other information you choose to provide</li>
                </ul>
                <p>b. User Content:</p>
                <ul>
                  <li>Materials uploaded for flashcard creation</li>
                </ul>
                <p>c. Usage Information:</p>
                <ul>
                  <li>Flashcard creation and usage data</li>
                  <li>Performance analytics</li>
                  <li>Token purchase and usage history</li>
                </ul>
                <p>d. Technical Information:</p>
                <ul>
                  <li>IP address</li>
                  <li>Browser type and version</li>
                  <li>Device information (including device identifiers for mobile users)</li>
                  <li>Operating system</li>
                  <li>Log data</li>
                </ul>
              </>
            )
          },
          {
            title: "2.2 Methods of Information Collection",
            content: (
              <>
                <p>a. Direct Collection: I collect information directly from you when you provide it to me through input fields when registering for or using the Service.</p>
                <p>b. Automated Collection: I automatically collect certain information when you visit, use, or navigate the Service. This information does not reveal your specific identity but may include device and usage information.</p>
              </>
            )
          },
          {
            title: "2.3 Use of Collected Information",
            content: (
              <>
                <p>I use your information to:</p>
                <ul>
                  <li>Provide, maintain, and improve the Service</li>
                  <li>Process token purchases</li>
                  <li>Generate automated flashcards</li>
                  <li>Provide performance analytics</li>
                  <li>Develop new features and functionality</li>
                  <li>Communicate with you about your account, updates, or promotional materials</li>
                  <li>Detect and prevent fraud or abuse</li>
                  <li>Comply with legal obligations</li>
                </ul>
                <p>The legal bases for processing your personal data include:</p>
                <ul>
                  <li>Performance of a contract (our Terms of Service)</li>
                  <li>Your consent</li>
                  <li>My legitimate interests (such as improving the Service)</li>
                  <li>Compliance with legal obligations</li>
                </ul>
              </>
            )
          },
          {
            title: "2.4 Sharing of Information",
            content: (
              <>
                <p>a. I may share your information with:</p>
                <ul>
                  <li>Service providers that help me operate the business and deliver the Service</li>
                  <li>Language Learning Model (LLM) providers, such as Anthropic or OpenAI, for processing flashcard creation requests</li>
                  <li>Law enforcement or other government entities when required by law</li>
                  <li>Other parties in connection with a company transaction, such as a merger or sale of assets</li>
                </ul>
                <p>b. I do not sell your personal information to third parties for their marketing purposes.</p>
                <p>c. I may share aggregated, de-identified data that cannot reasonably be used to identify you.</p>
              </>
            )
          },
          {
            title: "2.5 Data Storage and Security",
            content: (
              <>
                <p>a. Data Storage: I store your data on secure servers hosted by Heroku.</p>
                <p>b. Security Measures: I implement industry-standard security measures to protect your personal information, including:</p>
                <ul>
                  <li>HTTPS encryption for all data transmissions</li>
                  <li>SHA256 hashing for password storage</li>
                  <li>Standard PostgreSQL access management for database security</li>
                </ul>
                <p>However, no method of transmission over the Internet or electronic storage is 100% secure, and I cannot guarantee absolute security.</p>
              </>
            )
          },
          {
            title: "2.6 Cookies and Tracking Technologies",
            content: (
              <p>I use cookies and similar tracking technologies to track activity on the Service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
            )
          },
          {
            title: "2.7 Data Retention",
            content: (
              <>
                <p>I retain your personal information for as long as necessary to provide the Service and comply with legal obligations. Specifically:</p>
                <ul>
                  <li>Account information is retained for the duration of your account plus 30 days after account deletion to allow for account recovery.</li>
                  <li>Usage data is retained for 2 years for analytics purposes.</li>
                  <li>Financial transaction data is retained for 7 years to comply with tax and accounting regulations.</li>
                </ul>
                <p>You can request deletion of your account and associated data at any time. Upon such request, I will delete your personal information within 30 days, unless I am required to retain certain information for legal or accounting purposes.</p>
              </>
            )
          },
          {
            title: "2.8 User Rights",
            content: (
              <>
                <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
                <ul>
                  <li>The right to access your personal information</li>
                  <li>The right to rectify inaccurate information</li>
                  <li>The right to erase your information ("right to be forgotten")</li>
                  <li>The right to restrict processing of your information</li>
                  <li>The right to data portability</li>
                  <li>The right to object to certain processing activities</li>
                  <li>The right to withdraw consent at any time (where processing is based on consent)</li>
                </ul>
                <p>To exercise these rights, please contact me using the information provided at the end of this policy.</p>
              </>
            )
          },
          {
            title: "2.9 International Data Transfers",
            content: (
              <>
                <p>As Snapcards is operated from Israel, your information may be transferred to and processed in Israel and other countries. I will take appropriate measures to protect your personal information in accordance with this Privacy Policy and applicable law, including:</p>
                <ul>
                  <li>Implementing Standard Contractual Clauses approved by the European Commission for transfers of personal information</li>
                  <li>Obtaining your consent for certain transfers</li>
                </ul>
              </>
            )
          },
          {
            title: "2.10 Children's Privacy",
            content: (
              <p>The Service may be used by children under 13 under adult supervision. I do not knowingly collect personal identifiable information from children under 13 without adult supervision. If you are a parent or guardian and you believe that your child has provided personal information without your supervision, please contact me at hello@snapcards.online. I will take steps to remove such information and terminate the child's account.</p>
            )
          },
          {
            title: "2.11 Changes to This Privacy Policy",
            content: (
              <p>I may update this Privacy Policy from time to time. I will notify you of any significant changes by posting the new Privacy Policy on this page, updating the "Last Updated" date, and sending an email notification to the address associated with your account.</p>
            )
          },
          {
            title: "2.12 GDPR Compliance",
            content: (
              <p>Snapcards complies with the General Data Protection Regulation (GDPR). For EU residents, the data controller is Yonathan Peles. The Data Protection Officer can be contacted at yoni@snapcards.online.</p>
            )
          },
          {
            title: "2.13 California Consumer Privacy Act (CCPA) Compliance",
            content: (
              <>
                <p>For California residents, I comply with the California Consumer Privacy Act (CCPA). In addition to the rights outlined in section 2.8, California residents have the right to:</p>
                <ul>
                  <li>Know what personal information is being collected about them</li>
                  <li>Know whether their personal information is sold or disclosed and to whom</li>
                  <li>Say no to the sale of personal information</li>
                  <li>Access their personal information</li>
                  <li>Request deletion of their personal information</li>
                  <li>Not be discriminated against for exercising their privacy rights</li>
                </ul>
              </>
            )
          },
          {
            title: "2.14 Third-Party Services",
            content: (
              <p>I may use third-party services to support the Service. These services may have access to your personal information only to perform these tasks on my behalf and are obligated not to disclose or use it for any other purpose.</p>
            )
          },
          {
            title: "2.15 Data Breach Notification",
            content: (
              <p>In the event of a data breach that compromises your personal information, I will notify you and relevant authorities without undue delay and no later than 72 hours after becoming aware of the breach, where feasible.</p>
            )
          }
        ]
      },
      {
        title: "3. Terms of Use",
        subsections: [
          {
            title: "3.1 Acceptance of Terms",
            content: (
              <p>By accessing or using Snapcards, you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use the Service.</p>
            )
          },
          {
            title: "3.2 Description of Service",
            content: (
              <p>Snapcards provides a platform for users to create automated flashcards using AI technology based on content they upload. The Service is available via website and mobile application.</p>
            )
          },
          {
            title: "3.3 User Accounts",
            content: (
              <>
                <p>a. You must create an account to use the Service.</p>
                <p>b. You are responsible for maintaining the confidentiality of your account information.</p>
                <p>c. You are responsible for all activities that occur under your account.</p>
                <p>d. Users under the age of 13 may use Snapcards with adult supervision.</p>
              </>
            )
          },
          {
            title: "3.4 Token System",
            content: (
              <>
                <p>a. Users must purchase tokens (hereafter referred to as "credits") from Snapcards to create automated flashcards.</p>
                <p>b. Credits are non-refundable and non-transferable.</p>
                <p>c. Credits are valid for one year from the date of purchase. Unused credits will expire after this period.</p>
                <p>d. I reserve the right to modify the pricing and availability of credits at any time.</p>
                <p>e. Credits purchased can be used across all platforms (web and mobile) associated with your account.</p>
              </>
            )
          },
          {
            title: "3.5 User Content and Conduct",
            content: (
              <>
                <p>a. You retain all rights to the content you upload to Snapcards.</p>
                <p>b. By uploading content, you grant me a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display your User Content for the purpose of creating and providing flashcards.</p>
                <p>c. You are solely responsible for the content you upload and its legality. You represent and warrant that you have all necessary rights, licenses, and permissions to upload and use any content on the Service.</p>
                <p>d. You agree not to upload any content that infringes on the intellectual property rights of others.</p>
                <p>e. You agree to indemnify and hold me harmless from any claims resulting from your uploaded content or your use of the Service.</p>
                <p>f. I reserve the right to remove any content at my sole discretion, without notice or liability to you.</p>
              </>
            )
          },
          {
            title: "3.6 Limitation of Liability",
            content: (
              <>
                <p>To the maximum extent permitted by law:</p>
                <p>a. In no event shall I (Yonathan Peles) be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
                <ul>
                  <li>Your access to or use of or inability to access or use the Service;</li>
                  <li>Any conduct or content of any third party on the Service;</li>
                  <li>Any content obtained from the Service; and</li>
                  <li>Unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not I have been informed of the possibility of such damage.</li>
                </ul>
                <p>b. My total liability for any claims arising from or relating to these Terms or the Service shall not exceed the amount you paid to use the Service in the past twelve (12) months.</p>
              </>
            )
          },
          {
            title: "3.7 Disclaimer of Warranties",
            content: (
              <>
                <p>a. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. I expressly disclaim all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                <p>b. I make no warranty that:</p>
                <ul>
                  <li>The Service will meet your requirements;</li>
                  <li>The Service will be uninterrupted, timely, secure, or error-free;</li>
                  <li>The results that may be obtained from the use of the Service will be accurate or reliable;</li>
                  <li>The quality of any products, services, information, or other material purchased or obtained by you through the Service will meet your expectations.</li>
                </ul>
              </>
            )
          },
          {
            title: "3.8 Indemnification",
            content: (
              <>
                <p>You agree to defend, indemnify, and hold harmless Yonathan Peles and his affiliates, licensors, and service providers, and his and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to:</p>
                <p>a. Your violation of these Terms;</p>
                <p>b. Your use of the Service, including, but not limited to, your User Content, any use of the Service's content, services, and products other than as expressly authorized in these Terms;</p>
                <p>c. Your violation of any third-party right, including without limitation any intellectual property right, publicity, confidentiality, property, or privacy right.</p>
                <p>This indemnification obligation will survive the termination or expiration of these Terms and your use of the Service.</p>
              </>
            )
          },
          {
            title: "3.9 LLM-Generated Content",
            content: (
              <p>Flashcards generated by the LLM technology are provided for your personal use. I do not claim ownership of these generated flashcards, but I retain the right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display such generated content in connection with the Service.</p>
            )
          },
          {
            title: "3.10 Content Policy",
            content: (
              <p>Users agree not to upload or create content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or otherwise objectionable.</p>
            )
          },
          {
            title: "3.11 Intellectual Property",
            content: (
              <p>The Snapcards Service, including its original content, features, and functionality, is owned by me and protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
            )
          },
          {
            title: "3.12 Copyright Infringement",
            content: (
              <>
                <p>I respect the intellectual property rights of others and expect users of the Service to do the same. I will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to me. If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please provide my copyright agent with the following information:</p>
                <ol type="a">
                  <li>A physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</li>
                  <li>Identification of the copyrighted work claimed to have been infringed;</li>
                  <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit me to locate the material;</li>
                  <li>Your contact information, including your address, telephone number, and an email address;</li>
                  <li>A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;</li>
                  <li>A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
                </ol>
                <p>I reserve the right to remove content alleged to be infringing without prior notice, at my sole discretion, and without liability to you. In appropriate circumstances, I will also terminate a user's account if the user is determined to be a repeat infringer.</p>
                <p>My designated copyright agent for notice of alleged copyright infringement can be reached at: hello@snapcards.online</p>
              </>
            )
          },
          {
            title: "3.13 Termination",
            content: (
              <>
                <p>I reserve the right to terminate or suspend your account and access to Snapcards at my sole discretion, without notice, for conduct that I believe violates these Terms of Use or is harmful to other users, me, or third parties, or for any other reason. This termination applies to all platforms. In the event of account termination, any unused credits will be forfeited.</p>
                <p>You may appeal a termination decision by contacting me at appeals@snapcards.online within 30 days of the termination. I will review your appeal and respond within 14 business days.</p>
              </>
            )
          },
          {
            title: "3.14 Governing Law and Dispute Resolution",
            content: (
              <>
                <p>These Terms of Use shall be governed by and construed in accordance with the laws of Israel, without regard to its conflict of law provisions. Any dispute arising from or relating to the subject matter of these Terms shall be finally settled by arbitration in Tel Aviv, Israel, using the English language in accordance with the Arbitration Rules and Procedures of the Israeli Institute of Commercial Arbitration then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes.</p>
                <p>For disputes where the total amount sought is less than $10,000 USD, the parties agree to use online dispute resolution or small claims court as an alternative to arbitration.</p>
                <p>Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.</p>
              </>
            )
          },
          {
            title: "3.15 Severability",
            content: (
              <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.</p>
            )
          },
          {
            title: "3.16 Force Majeure",
            content: (
              <p>I shall not be liable for any delay or failure to perform resulting from causes outside my reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor or materials.</p>
            )
          },
          {
            title: "3.17 Export Control",
            content: (
              <p>You agree to comply with all applicable export and re-export control laws and regulations, including the Export Administration Regulations maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department's Office of Foreign Assets Control, and the International Traffic in Arms Regulations maintained by the Department of State.</p>
            )
          },
          {
            title: "3.18 Changes to Terms",
            content: (
              <p>I may update these Terms of Use from time to time. I will notify you of any significant changes by posting the new Terms of Use on this page, updating the "Last Updated" date at the top of this page, and sending an email notification to the address associated with your account. Your continued use of the Service after such modifications will constitute your acknowledgment and agreement to the modified Terms of Use.</p>
            )
          },
          {
            title: "3.19 Severability and Waiver",
            content: (
              <>
                <p>If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>
                <p>No waiver of by me of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of mine to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.</p>
              </>
            )
          },
          {
            title: "3.20 Entire Agreement",
            content: (
              <p>The Terms of Use and Privacy Policy constitute the sole and entire agreement between you and me with respect to the Service and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Service.</p>
            )
          }
        ]
      },
      {
        title: "4. Contact Information",
        content: (
          <>
            <p>If you have any questions about this Privacy Policy or Terms of Use, please contact me at:</p>
            <p>hello@snapcards.online</p>
            <p>Data Protection Officer: yoni@snapcards.online</p>
            <p>Yonathan Peles [Contact information withheld for privacy]</p>
            <p>By using Snapcards, you acknowledge that you have read and understood this Privacy Policy and Terms of Use, and agree to be bound by them. You further acknowledge that these Terms of Use and Privacy Policy constitute a binding contract between you and Yonathan Peles, despite the Service being operated by an individual rather than a company.</p>
          </>
        )
      }
    ]
  },
  footer: HEADER_CONFIG.footer
};