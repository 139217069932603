import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const pitches = [
  "ace your exams",
  "nail that job interview",
  "never forget your lines",
  "know the dates of everything",
  "master a new language",
  "become a trivia champion"
];

const AnimatedPitch = () => {
  const [currentPitchIndex, setCurrentPitchIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPitchIndex((prevIndex) => (prevIndex + 1) % pitches.length);
    }, 3000); // Change pitch every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-center py-8 px-4">
      <h2 className="text-4xl font-bold mb-4 text-black">memorize anything, forever.</h2>
      <div className="h-16 flex items-center justify-center">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPitchIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-2xl font-semibold text-[#07408B]"
          >
            {pitches[currentPitchIndex]}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AnimatedPitch;