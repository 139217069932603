import React from 'react';
import { Link } from 'react-router-dom';
import { Users, BookOpen, Trophy, Sparkles } from 'lucide-react';

import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import Header from './Header';
import Footer from './Footer';

import { ABOUT_PAGE_CONTENT } from './aboutPageContent';

const iconComponents = {
  Users,
  BookOpen,
  Trophy,
  Sparkles
};

const ValueCard = ({ icon, title, description }) => {
  const IconComponent = iconComponents[icon];
  return (
    <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
      <CardHeader>
        <CardTitle className="flex items-center text-xl font-bold">
          {IconComponent && <IconComponent className="h-6 w-6 text-snapcards-blue" />}
          <span className="ml-2">{title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-gray-600">{description}</p>
      </CardContent>
    </Card>
  );
};

const AboutUs = () => {
  const { header, hero, values, story, cta, footer } = ABOUT_PAGE_CONTENT;

  return (
    <div className="min-h-screen bg-snapcards-cream text-black font-serif">
      <Header title={header.title} logo={header.logo} navigation={header.navigation} />

      <section className="py-16 border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">{story.title}</h2>
            <p className="text-xl max-w-3xl mx-auto text-center">{story.content}</p>
          </div>
      </section>

      <main>
        <section className="py-16 border-t border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{values.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {values.items.map((value, index) => (
                <ValueCard
                  key={index}
                  icon={value.icon}
                  title={value.title}
                  description={value.description}
                />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">{cta.title}</h2>
            <p className="text-xl mb-12">{cta.description}</p>
            <Button asChild size="lg" className="bg-snapcards-blue text-white hover:bg-blue-700">
              <Link to={cta.button.path}>{cta.button.label}</Link>
            </Button>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default AboutUs;