import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { AlertTriangle } from 'lucide-react';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-snapcards-cream flex items-center justify-center p-4">
      <Card className="w-full max-w-2xl bg-white shadow-lg">
        <CardHeader className="text-center">
          <CardTitle className="text-3xl font-bold text-black mb-2">404: Page Not Found</CardTitle>
          <p className="text-xl text-gray-600">Even Snapcards doesn't remember this page...</p>
        </CardHeader>
        <CardContent className="text-center py-8">
          <div className="flex justify-center mb-6">
            <AlertTriangle className="h-16 w-16 text-yellow-500" />
          </div>
          <Button 
            onClick={() => navigate('/')} 
            className="bg-snapcards-blue text-white text-lg px-6 py-3"
          >
            Go to Home
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default PageNotFound;
