import { HEADER_CONFIG } from './headerConfig';

export const RESEARCH_PAGE_CONTENT = {
  header: HEADER_CONFIG,
  researchReview: {
      title: "Academic Review: Spaced Repetition in Learning and Memory",
      sections: [
        {
          title: "Introduction",
          content: "This review synthesizes current research on spaced repetition, examining its efficacy in long-term knowledge retention and skill acquisition. The following analysis is grounded in empirical studies and meta-analyses, providing a comprehensive overview of the cognitive mechanisms underlying spaced repetition and its practical applications in educational contexts."
        },
        {
          title: "Theoretical Foundations",
          subsections: [
            {
              title: "Spacing Effect",
              content: "The spacing effect, first identified by <a href='#ref4'>Ebbinghaus (1885/1913)</a>, refers to the phenomenon whereby information learned and reviewed at spaced intervals is better retained than information studied in a single, massed session. Recent neurobiological research suggests that this effect may be linked to the consolidation of memories in the hippocampus and their subsequent transfer to neocortical regions for long-term storage (<a href='#ref6'>Karlsson Wirebring et al., 2015</a>)."
            },
            {
              title: "Retrieval Practice",
              content: "The act of retrieval, central to spaced repetition systems, has been shown to enhance long-term retention more effectively than passive review (<a href='#ref11'>Roediger & Butler, 2011</a>). This \"testing effect\" is thought to strengthen memory traces and improve metacognitive monitoring (<a href='#ref3'>Dunlosky et al., 2013</a>)."
            }
          ]
        },
        {
          title: "Empirical Findings",
          subsections: [
            {
              title: "Efficacy in Various Domains",
              content: `
                <ol>
                  <li><strong>Medical Education</strong>: A meta-analysis by <a href='#ref2'>Dobson et al. (2017)</a> found that spaced repetition significantly improved knowledge retention among medical students, with effect sizes ranging from medium to large (d = 0.7 to 1.2).</li>
                  <li><strong>Language Acquisition</strong>: In a study of vocabulary learning, <a href='#ref9'>Nakata (2015)</a> demonstrated that expanding retrieval practice schedules were as effective as equal-interval schedules, contradicting earlier assumptions about the superiority of expanding intervals.</li>
                  <li><strong>Mathematics</strong>: <a href='#ref12'>Rohrer and Taylor (2006)</a> found that spaced practice led to better long-term retention and transfer of mathematical skills compared to massed practice.</li>
                </ol>
              `
            },
            {
              title: "Optimal Intervals and Algorithms",
              content: "Research on optimal spacing intervals has yielded mixed results. While some studies suggest that expanding intervals are most effective (<a href='#ref8'>Karpicke & Roediger, 2007</a>), others have found equal or superior effects with fixed intervals (<a href='#ref7'>Karpicke & Bauernschmidt, 2011</a>). A comprehensive review by <a href='#ref5'>Gwern Branwen (2009/2020)</a> concludes that \"expanding spacing is roughly as good as or better than (wide) fixed intervals, but expanding is more convenient and the default.\""
            },
            {
              title: "Integration with Other Learning Techniques",
              content: `
                The combination of spaced repetition with other evidence-based learning strategies has shown promising results:
                <ol>
                  <li><strong>Elaborative Rehearsal</strong>: When combined with elaborative encoding techniques, spaced repetition has been shown to enhance both retention and comprehension (<a href='#ref10'>Rawson & Dunlosky, 2011</a>).</li>
                  <li><strong>Interleaving</strong>: Alternating between different types of problems or concepts during spaced practice can further enhance learning and transfer (<a href='#ref13'>Rohrer et al., 2015</a>).</li>
                </ol>
              `
            }
          ]
        },
        {
          title: "Practical Implications",
          content: `
            <ol>
              <li><strong>Minimum Effective Duration</strong>: Based on meta-analytic findings, <a href='#ref1'>Cepeda et al. (2008)</a> suggest that spaced repetition is most effective for retention intervals of at least 5 days, with optimal spacing intervals typically being 10-20% of the desired retention interval.</li>
              <li><strong>Resource Allocation</strong>: Given the time investment required for effective spaced repetition, it is most efficiently applied to information that is both important and challenging to retain (<a href='#ref15'>Weinstein et al., 2018</a>).</li>
              <li><strong>Digital Implementation</strong>: Modern spaced repetition software (SRS) allows for the implementation of complex scheduling algorithms and the integration of multimedia content, potentially enhancing the method's effectiveness and accessibility (<a href='#ref14'>Settles & Meeder, 2016</a>).</li>
            </ol>
          `
        },
        {
          title: "Conclusion",
          content: "The empirical evidence strongly supports the efficacy of spaced repetition as a learning technique, particularly for long-term retention of factual knowledge and procedural skills. While questions remain regarding optimal implementation strategies, the fundamental principles of spaced repetition are well-established in cognitive science literature. Future research should focus on refining algorithms for personalized spacing schedules and investigating the interaction between spaced repetition and emerging educational technologies."
        }
      ],
      references: [
        { id: "ref1", text: "Cepeda, N. J., Vul, E., Rohrer, D., Wixted, J. T., & Pashler, H. (2008). Spacing effects in learning: A temporal ridgeline of optimal retention. Psychological Science, 19(11), 1095-1102." },
        { id: "ref2", text: "Dobson, J. L., Perez, J., & Linderholm, T. (2017). Distributed retrieval practice promotes superior recall of anatomy information. Anatomical Sciences Education, 10(4), 339-347." },
        { id: "ref3", text: "Dunlosky, J., Rawson, K. A., Marsh, E. J., Nathan, M. J., & Willingham, D. T. (2013). Improving students' learning with effective learning techniques: Promising directions from cognitive and educational psychology. Psychological Science in the Public Interest, 14(1), 4-58." },
        { id: "ref4", text: "Ebbinghaus, H. (1913). Memory: A contribution to experimental psychology. (H. A. Ruger & C. E. Bussenius, Trans.). New York: Teachers College, Columbia University. (Original work published 1885)" },
        { id: "ref5", text: "Gwern Branwen. (2020). Spaced repetition. Retrieved from https://www.gwern.net/Spaced-repetition" },
        { id: "ref6", text: "Karlsson Wirebring, L., Wiklund-Hörnqvist, C., Eriksson, J., Andersson, M., Jonsson, B., & Nyberg, L. (2015). Lesser neural pattern similarity across repeated tests is associated with better long-term memory retention. Journal of Neuroscience, 35(26), 9595-9602." },
        { id: "ref7", text: "Karpicke, J. D., & Bauernschmidt, A. (2011). Spaced retrieval: Absolute spacing enhances learning regardless of relative spacing. Journal of Experimental Psychology: Learning, Memory, and Cognition, 37(5), 1250-1257." },
        { id: "ref8", text: "Karpicke, J. D., & Roediger III, H. L. (2007). Expanding retrieval practice promotes short-term retention, but equally spaced retrieval enhances long-term retention. Journal of Experimental Psychology: Learning, Memory, and Cognition, 33(4), 704-719." },
        { id: "ref9", text: "Nakata, T. (2015). Effects of expanding and equal spacing on second language vocabulary learning: Does gradually increasing spacing increase vocabulary learning? Studies in Second Language Acquisition, 37(4), 677-711." },
        { id: "ref10", text: "Rawson, K. A., & Dunlosky, J. (2011). Optimizing schedules of retrieval practice for durable and efficient learning: How much is enough? Journal of Experimental Psychology: General, 140(3), 283-302." },
        { id: "ref11", text: "Roediger III, H. L., & Butler, A. C. (2011). The critical role of retrieval practice in long-term retention. Trends in Cognitive Sciences, 15(1), 20-27." },
        { id: "ref12", text: "Rohrer, D., & Taylor, K. (2006). The effects of overlearning and distributed practise on the retention of mathematics knowledge. Applied Cognitive Psychology, 20(9), 1209-1224." },
        { id: "ref13", text: "Rohrer, D., Dedrick, R. F., & Stershic, S. (2015). Interleaved practice improves mathematics learning. Journal of Educational Psychology, 107(3), 900-908." },
        { id: "ref14", text: "Settles, B., & Meeder, B. (2016). A trainable spaced repetition model for language learning. In Proceedings of the 54th Annual Meeting of the Association for Computational Linguistics (Volume 1: Long Papers) (pp. 1848-1858)." },
        { id: "ref15", text: "Weinstein, Y., Madan, C. R., & Sumeracki, M. A. (2018). Teaching the science of learning. Cognitive Research: Principles and Implications, 3(1), 2." }
      ]
    },
    footer: {
      companyInfo: {
        name: "Snapcards",
        description: "AI-powered flashcards for effective learning",
      },
      quickLinks: [
        { label: "About Us", path: "/about" },
        { label: "Blog", path: "/blog" },
        { label: "Careers", path: "/careers" },
      ],
      legal: [
        { label: "Terms of Service", path: "/terms" },
        { label: "Privacy Policy", path: "/privacy" },
      ],
      socialMedia: [
        { platform: "Twitter", url: "https://twitter.com/snapcards" },
        { platform: "Facebook", url: "https://facebook.com/snapcards" },
        { platform: "LinkedIn", url: "https://linkedin.com/company/snapcards" },
      ],
      copyright: "© 2024 Snapcards. All rights reserved.",
    },
};