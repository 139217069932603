import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/globals.css';
import Snapcards from './components/app/Snapcards';
import LandingPage from './components/landing/LandingPage';
import About from './components/landing/About';
import Research from './components/landing/Research';
import Pricing from './components/landing/Pricing';
import TermsPage from './components/landing/Terms';
import EmailVerificationScreen from './components/app/EmailVerificationScreen';
import PageNotFound from './components/landing/PageNotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/research" element={<Research />} />
        <Route path="/app" element={<Snapcards />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/verify-email/:token" element={<EmailVerificationScreen />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
