import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileSidebar from './ProfileSidebar';

const Header = ({ user, onLogout, showBackButton = false, setCurrentScreen }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (setCurrentScreen) {
      setCurrentScreen('deck');
    }
  };

  return (
    <header className="bg-snapcards-cream shadow-md sticky top-0 z-10 border-b border-black">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            {showBackButton && (
              <button
                onClick={() => navigate(-1)}
                className="mr-4 text-gray-600 hover:text-gray-900"
              >
                &larr; Back
              </button>
            )}
            <div 
              className="flex items-center cursor-pointer" 
              onClick={handleLogoClick}
            >
              <img src="/icon.png" alt="Snapcards Logo" className="h-8 w-8 mr-2" />
              <h1 className="text-2xl font-bold text-black">Snapcards</h1>
            </div>
          </div>
          <ProfileSidebar user={user} onLogout={onLogout} />
        </div>
      </div>
    </header>
  );
};

export default Header;