import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import Header from './Header';
import Footer from './Footer';
import { TERMS_PAGE_CONTENT } from './termsContent';

const TermsSection = ({ title, content, subsections }) => (
  <Card className="bg-white shadow-lg border border-gray-200 mb-6">
    <CardHeader>
      <CardTitle className="text-2xl font-bold text-snapcards-blue">
        {title}
      </CardTitle>
    </CardHeader>
    <CardContent>
      {content && (
        <div className="text-gray-700 leading-relaxed mb-4">
          {content}
        </div>
      )}
      {subsections && subsections.map((subsection, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-xl font-semibold text-black mb-2">
            {subsection.title}
          </h3>
          <div className="text-gray-700 leading-relaxed">
            {subsection.content}
          </div>
        </div>
      ))}
    </CardContent>
  </Card>
);

const TermsPage = () => {
  const { header, termsAndPolicy, footer } = TERMS_PAGE_CONTENT;

  return (
    <div className="min-h-screen bg-snapcards-cream text-black font-serif">
      <Header {...header} />

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">{termsAndPolicy.title}</h1>
        <p className="text-center mb-8 text-gray-600">{termsAndPolicy.lastUpdated}</p>
        
        {termsAndPolicy.sections.map((section, index) => (
          <TermsSection 
            key={index}
            title={section.title}
            content={section.content}
            subsections={section.subsections}
          />
        ))}
      </main>

      <Footer />
    </div>
  );
};

export default TermsPage;