import React, { useState, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { SmartInputPanel } from '../ui/input-placeholder';
import { Upload } from 'lucide-react';
import * as api from '../../services/api';

const GenerationCard = ({ deckId, onGenerationComplete }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputType, setInputType] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const smartInputRef = useRef(null);

  const handleInputChange = (value, type) => {
    setInputValue(value);
    setInputType(type);
  };

  const clearInput = () => {
    setInputValue('');
    setInputType('');
    if (smartInputRef.current) {
      smartInputRef.current.clearInput();
    }
  };

  const checkTaskProgress = async (taskId) => {
    try {
      console.log(`Checking task progress for task ${taskId}`);
      const response = await api.checkTaskStatus(taskId);
      console.log(`Task status response:`, response.data);

      if (response.data.status === 'pending') {
        console.log(`Task ${taskId} still in progress. Checking again in 2 seconds.`);
        setTimeout(() => checkTaskProgress(taskId), 2000);
      } else if (response.data.status === 'completed') {
        console.log(`Task ${taskId} completed successfully. Calling onGenerationComplete.`);
        await onGenerationComplete();
        console.log(`onGenerationComplete finished for task ${taskId}`);
        clearInput();
        setIsGenerating(false);
        setTaskId(null);
      } else {
        console.error(`Task ${taskId} failed or returned unexpected status`);
        setIsGenerating(false);
        setTaskId(null);
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setIsGenerating(false);
      setTaskId(null);
    }
  };

  const handleGenerateCards = async () => {
    if (!inputValue) {
      return;
    }

    setIsGenerating(true);

    try {
      console.log(`Initiating card generation for deck ${deckId}`);
      const formData = new FormData();
      if (inputType === 'file' && inputValue instanceof File) {
        formData.append('file', inputValue);
      } else {
        formData.append('content', inputValue);
      }
      formData.append('input_type', inputType);

      const response = await api.generateCards(deckId, formData);
      console.log(`Card generation initiated. Task ID:`, response.task_id);
      setTaskId(response.task_id);
      checkTaskProgress(response.task_id);
    } catch (error) {
      console.error('Error initiating card generation:', error);
      setIsGenerating(false);
    }
  };

  return (
    <Card className="mb-8 bg-white shadow-md border border-gray-200">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Generate Cards</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col md:flex-row md:items-start space-y-4 md:space-y-0 md:space-x-4">
          <SmartInputPanel
            ref={smartInputRef}
            onInputChange={handleInputChange}
            placeholder="Type, paste link, drag, or"
            className="w-full md:flex-grow"
          />
          <Button 
            onClick={handleGenerateCards} 
            disabled={isGenerating || !inputValue} 
            className="bg-snapcards-blue text-white hover:bg-blue-700 whitespace-nowrap w-full md:w-auto"
          >
            {isGenerating ? (
              <>
                <Upload className="h-4 w-4 mr-2 animate-spin" />
                Generating...
              </>
            ) : (
              <>
                <Upload className="h-4 w-4 mr-2" />
                Generate Cards
              </>
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default GenerationCard;
