import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import Header from './Header';
import Footer from './Footer';

import { RESEARCH_PAGE_CONTENT } from './researchPageContent';

const JumpBackButton = ({ onClick }) => {
  return (
    <button
      className="mr-2 inline-flex items-center px-2 py-1 text-sm bg-snapcards-blue text-white rounded-full shadow-sm transition-all hover:bg-blue-700"
      onClick={onClick}
    >
      ↑ Jump Back
    </button>
  );
};

const ResearchSection = ({ title, content, subsections, onReferenceClick, activeCitation }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.tagName === 'A' && e.target.getAttribute('href').startsWith('#ref')) {
        e.preventDefault();
        const targetId = e.target.getAttribute('href').slice(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          onReferenceClick(targetId, e.target);
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    };

    const current = contentRef.current;
    current.addEventListener('click', handleClick);
    return () => current.removeEventListener('click', handleClick);
  }, [onReferenceClick]);

  useEffect(() => {
    if (contentRef.current) {
      const links = contentRef.current.querySelectorAll('a[href^="#ref"]');
      links.forEach(link => {
        const refId = link.getAttribute('href').slice(1);
        if (activeCitation === refId) {
          link.classList.add('active-citation');
        } else {
          link.classList.remove('active-citation');
        }
      });
    }
  }, [activeCitation]);

  return (
    <Card className="bg-white shadow-lg border border-gray-200 mb-6">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-snapcards-blue">
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent ref={contentRef}>
        {content && (
          <div 
            className="text-gray-700 leading-relaxed mb-4 citation-content" 
            dangerouslySetInnerHTML={{ __html: content }} 
          />
        )}
        {subsections && subsections.map((subsection, index) => (
          <div key={index} className="mb-4">
            <h3 className="text-xl font-semibold text-black mb-2">
              {subsection.title}
            </h3>
            <div 
              className="text-gray-700 leading-relaxed citation-content" 
              dangerouslySetInnerHTML={{ __html: subsection.content }} 
            />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

const References = ({ references, activeRef, onJumpBack }) => {
  return (
    <Card className="bg-white shadow-lg border border-gray-200 mt-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-snapcards-blue">
          References
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ol className="list-decimal list-inside">
          {references.map((ref, index) => (
            <li 
              key={index} 
              id={ref.id} 
              className="mb-2 flex items-start"
            >
              {activeRef === ref.id && (
                <JumpBackButton onClick={() => onJumpBack(ref.id)} />
              )}
              <span className={`reference-text ${activeRef === ref.id ? 'highlighted-reference' : 'text-gray-700'}`}>
                {ref.text}
              </span>
            </li>
          ))}
        </ol>
      </CardContent>
    </Card>
  );
};

const ResearchPage = () => {
  const { header, researchReview, footer } = RESEARCH_PAGE_CONTENT;
  const [activeRef, setActiveRef] = useState(null);
  const [activeCitation, setActiveCitation] = useState(null);

  const handleReferenceClick = useCallback((refId, citationElement) => {
    setActiveRef(refId);
    setActiveCitation(refId);
  }, []);

  const handleJumpBack = useCallback((refId) => {
    const citation = document.querySelector(`a[href="#${refId}"]`);
    if (citation) {
      citation.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setActiveRef(null);
      setActiveCitation(null);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (activeCitation) {
        const citation = document.querySelector(`a[href="#${activeCitation}"]`);
        if (citation) {
          const rect = citation.getBoundingClientRect();
          if (rect.top < 0 || rect.bottom > window.innerHeight) {
            setActiveCitation(null);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeCitation]);

  return (
    <div className="min-h-screen bg-snapcards-cream text-black font-serif">
      <style jsx global>{`
        .citation-content a {
          color: #3b82f6;
          text-decoration: underline;
          transition: color 0.3s ease;
        }
        .citation-content a:hover {
          color: #2563eb;
        }
        .bg-snapcards-blue {
          background-color: #1e40af;
        }
        .hover\:bg-blue-700:hover {
          background-color: #1d4ed8;
        }
        .active-citation {
          color: #1e40af !important;
          font-weight: bold;
        }
        .highlighted-reference {
          background-color: #1e40af;
          color: white;
          padding: 2px 4px;
          border-radius: 2px;
        }
        .reference-text {
          display: inline;
        }
      `}</style>
      <Header title={header.title} logo={header.logo} navigation={header.navigation} />

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">{researchReview.title}</h1>
        
        {researchReview.sections.map((section, index) => (
          <ResearchSection
            key={index}
            title={section.title}
            content={section.content}
            subsections={section.subsections}
            onReferenceClick={handleReferenceClick}
            activeCitation={activeCitation}
          />
        ))}

        <References 
          references={researchReview.references} 
          activeRef={activeRef}
          onJumpBack={handleJumpBack}
        />
      </main>

      <Footer 
        companyInfo={footer.companyInfo}
        quickLinks={footer.quickLinks}
        legal={footer.legal}
        socialMedia={footer.socialMedia}
        copyright={footer.copyright}
      />
    </div>
  );
};

export default ResearchPage;