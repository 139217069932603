import React from 'react';
import { Link } from 'react-router-dom';
import { Check, HelpCircle, Zap } from 'lucide-react';

import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import Header from './Header';
import Footer from './Footer';

import { PRICING_PAGE_CONTENT } from './pricingPageContent';

const CreditPackCard = ({ amount, price, perCard, label }) => (
  <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
    <CardHeader className="text-center">
      <CardTitle className="text-2xl font-bold">{label}</CardTitle>
      <div className="text-4xl font-bold text-snapcards-blue mt-2">${price}</div>
      <div className="text-gray-600 mt-1">{amount} credits</div>
    </CardHeader>
    <CardContent>
      <div className="text-center mb-4">
        <span className="text-sm font-medium">${perCard.toFixed(3)}</span> per card
      </div>
      <Button className="w-full bg-snapcards-blue text-white hover:bg-blue-700">
        Purchase
      </Button>
    </CardContent>
  </Card>
);

const FeatureItem = ({ feature }) => (
  <li className="flex items-center mb-2">
    <Check className="h-5 w-5 text-snapcards-blue mr-2" />
    <span className="text-gray-600">{feature}</span>
  </li>
);

const InfoItem = ({ title, description }) => (
  <div className="mb-6">
    <h3 className="text-lg font-bold mb-2 flex items-center">
      <Zap className="h-5 w-5 text-snapcards-blue mr-2" />
      {title}
    </h3>
    <p className="text-gray-600 ml-7">{description}</p>
  </div>
);

const FAQItem = ({ question, answer }) => (
  <Card className="bg-white border border-gray-200 mb-4">
    <CardHeader>
      <CardTitle className="flex items-center">
        <HelpCircle className="h-5 w-5 text-snapcards-blue mr-2" />
        {question}
      </CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-gray-600">{answer}</p>
    </CardContent>
  </Card>
);

const Pricing = () => {
  const { header, hero, creditPacks, features, additionalInfo, faq, cta, footer } = PRICING_PAGE_CONTENT;

  return (
    <div className="min-h-screen bg-snapcards-cream text-black font-serif">
      <Header title={header.title} logo={header.logo} navigation={header.navigation} />

      <main>
        <section className="container mx-auto px-4 py-12 text-center">
          <h1 className="text-5xl font-bold mb-4">{hero.title}</h1>
          <p className="text-xl mb-8 text-gray-600">{hero.description}</p>
        </section>

        <section className="container mx-auto px-4 mb-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {creditPacks.map((pack, index) => (
              <CreditPackCard key={index} {...pack} />
            ))}
          </div>
        </section>

        <section className="py-16 border-t border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Included with All Packs</h2>
            <ul className="max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
              {features.map((feature, index) => (
                <FeatureItem key={index} feature={feature} />
              ))}
            </ul>
          </div>
        </section>

        <section className="py-16 border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{additionalInfo.title}</h2>
            <div className="max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
              {additionalInfo.items.map((item, index) => (
                <InfoItem key={index} {...item} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{faq.title}</h2>
            <div className="max-w-3xl mx-auto">
              {faq.items.map((item, index) => (
                <FAQItem key={index} {...item} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">{cta.title}</h2>
            <p className="text-xl mb-8 text-gray-600">{cta.description}</p>
            <Button asChild size="lg" className="bg-snapcards-blue text-white hover:bg-blue-700">
              <Link to={cta.button.path}>{cta.button.label}</Link>
            </Button>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Pricing;