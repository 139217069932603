import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Brain, ChevronRight, Clock, Users, Award, Zap } from 'lucide-react';

import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import AnimatedPitch from './AnimatedPitch';
import Header from './Header';
import Footer from './Footer';

import { LANDING_PAGE_CONTENT } from './landingPageContent';

const iconComponents = {
  Brain,
  ChevronRight,
  Clock,
  Users,
  Award,
  Zap
};

const FeatureCard = ({ icon, title, description }) => {
  const IconComponent = iconComponents[icon];
  return (
    <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
      <CardHeader>
        <CardTitle className="flex items-center text-xl font-bold">
          {IconComponent && <IconComponent className="h-6 w-6 text-snapcards-blue" />}
          <span className="ml-2">{title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-gray-600">{description}</p>
      </CardContent>
    </Card>
  );
};

const TestimonialCard = ({ name, role, quote }) => (
  <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
    <CardContent className="text-center pt-6">
      <p className="text-gray-600 italic mb-4">"{quote}"</p>
      <p className="font-bold">{name}</p>
      <p className="text-sm text-gray-500">{role}</p>
    </CardContent>
  </Card>
);

const LandingPage = () => {
  const { header, hero, features, callToAction, testimonials, faq, footer } = LANDING_PAGE_CONTENT;
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/app');
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-snapcards-cream text-black font-serif">
      <Header title={header.title} logo={header.logo} navigation={header.navigation} />

      <main>
        <section className="container mx-auto px-4 py-12">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8 relative">
            <div className="w-full md:w-1/2 text-left">
              <h2 className="text-5xl font-bold text-black mb-4">
                {hero.title.part1}
              </h2>
              <h2 className="text-5xl font-bold text-snapcards-blue mb-8">
                {hero.title.part2}
              </h2>
              <p className="text-xl text-black mb-8">
                {hero.description}
              </p>
              <div className="flex space-x-4">
                <Button asChild size="lg" className="bg-snapcards-blue text-white hover:bg-blue-700">
                  <Link to={hero.cta.primary.path}>{hero.cta.primary.label}</Link>
                </Button>
                <Button asChild size="lg" className="bg-white text-snapcards-blue hover:bg-gray-100 border border-snapcards-blue">
                  <Link to={hero.cta.secondary.path}>{hero.cta.secondary.label}</Link>
                </Button>
              </div>
            </div>
            <div className="hidden md:block w-0.5 bg-black absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2"></div>
            <div className="w-full md:w-1/2">
              <AnimatedPitch />
            </div>
          </div>
        </section>

        <section className="py-16 border-t border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{features.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.items.map((feature, index) => (
                <FeatureCard
                  key={index}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 border-b border-gray-300">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">{callToAction.title}</h2>
            <p className="text-xl mb-12">{callToAction.description}</p>
            <Button asChild size="lg" className="bg-snapcards-blue text-white hover:bg-blue-700">
              <Link to={callToAction.button.path}>{callToAction.button.label}</Link>
            </Button>
          </div>
        </section>

        <section className="py-16 border-b border-gray-300">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{testimonials.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {testimonials.items.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  name={testimonial.name}
                  role={testimonial.role}
                  quote={testimonial.quote}
                />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">{faq.title}</h2>
            <div className="space-y-8 max-w-3xl mx-auto">
              {faq.items.map((item, index) => (
                <Card key={index} className="bg-white border border-gray-200">
                  <CardHeader>
                    <CardTitle>{item.question}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p>{item.answer}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;