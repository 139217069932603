import { HEADER_CONFIG } from './headerConfig';

export const LANDING_PAGE_CONTENT = {
  header: HEADER_CONFIG,
  hero: {
    title: {
      part1: "The flashcards app for",
      part2: "AI-powered learning"
    },
    description: "Snapcards harnesses the power of Large Language Models to create an adaptive, intelligent flashcard experience that revolutionizes your learning journey.",
    cta: {
      primary: { label: "Log In", path: "/app" },
      secondary: { label: "Sign Up", path: "/app" }
    }
  },
  features: {
    title: "Why Choose Snapcards?",
    items: [
      {
        icon: "Brain",
        title: "AI-Powered Learning",
        description: "Our LLM technology adapts to your learning style, creating personalized flashcards and quizzes tailored to your specific needs."
      },
      {
        icon: "Zap",
        title: "Efficiency and Quality",
        description: "Learn faster and retain information better with our research-backed algorithm, optimizing the key principles of spaced repetition."
      },
      {
        icon: "Clock",
        title: "Time-Saving",
        description: "Automatically generate flashcards from your notes or textbooks, saving you hours of manual work."
      },
      {
        icon: "Users",
        title: "Collaborative Learning",
        description: "Share decks and study together with friends, classmates, or co-workers."
      },
      {
        icon: "Award",
        title: "Progress Tracking",
        description: "Visualize your learning progress with detailed analytics and insights."
      },
      {
        icon: "ChevronRight",
        title: "Multi-Platform Access",
        description: "Access your flashcards anytime, anywhere on web, iOS, and Android devices."
      }
    ]
  },
  callToAction: {
    title: "Unlock Your Learning Potential",
    description: "Whatever it is you want to learn, Snapcards will be a gamechanger.",
    button: {
      label: "Start Learning Now",
      path: "/app"
    }
  },
  testimonials: {
    title: "What Our Users Say (placeholder)",
    items: [
      {
        name: "Sarah L.",
        role: "Medical Student",
        quote: "Snapcards has revolutionized the way I study for my exams. The AI-generated cards are spot-on and save me so much time!"
      },
      {
        name: "Mark T.",
        role: "Software Engineer",
        quote: "As a developer, staying up-to-date with new technologies is crucial. Snapcards helps me retain information from tech articles and documentation effortlessly."
      },
      {
        name: "Emily R.",
        role: "Language Learner",
        quote: "Learning a new language has never been easier. The personalized flashcards and pronunciation guides are incredibly helpful."
      }
    ]
  },
  faq: {
    title: "Frequently Asked Questions",
    items: [
      {
        question: "How does Snapcards use AI?",
        answer: "Snapcards utilizes advanced Large Language Models to generate intelligent flashcards, provide explanations, and adapt to your learning style. Our AI analyzes your performance and adjusts the difficulty and frequency of cards to optimize your learning experience."
      },
      {
        question: "Is Snapcards free to use?",
        answer: "We offer a free tier with basic features and limited AI capabilities. For full access to our AI-powered features and unlimited decks, check out our premium plans on the Pricing page."
      },
      {
        question: "Can I import my existing flashcards?",
        answer: "Yes! Snapcards supports importing from popular flashcard formats and services. Our AI can also enhance your existing cards with additional context and generate related cards to reinforce your learning."
      }
    ]
  },
  footer: {
    companyInfo: {
      name: "Snapcards",
      slogan: "Innovating the Future of Learning"
    },
    quickLinks: [
      { label: "About Us", path: "/about" },
      { label: "Research", path: "/research" },
      { label: "Pricing", path: "/pricing" },
      { label: "Contact", path: "/contact" }
    ],
    legal: [
      { label: "Terms of Service", path: "/terms" },
      { label: "Privacy Policy", path: "/privacy" }
    ],
    socialMedia: [
      { platform: "Facebook", url: "#" },
      { platform: "Twitter", url: "#" },
      { platform: "Instagram", url: "#" },
      { platform: "LinkedIn", url: "#" }
    ],
    copyright: "© 2024 Snapcards. All rights reserved."
  }
};