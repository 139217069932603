import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DeckScreen from './DeckScreen';
import TopicScreen from './TopicScreen';
import DeckStudyScreen from './DeckStudyScreen';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import RegistrationConfirmedScreen from './RegistrationConfirmedScreen';
import EmailVerificationScreen from './EmailVerificationScreen';
import Header from './Header';
import { Button } from '../ui/button';
import { Edit, Book } from 'lucide-react';
import * as api from '../../services/api';

const Snapcards = () => {
    const [currentDeck, setCurrentDeck] = useState(null);
    const [currentTopic, setCurrentTopic] = useState(null);
    const [currentScreen, setCurrentScreen] = useState('login');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [registeredEmail, setRegisteredEmail] = useState('');
    const [isFreeReview, setIsFreeReview] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchRecentDeck = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const response = await api.getMostRecentDeck();
                console.log('Recent deck fetched:',response)
                setCurrentDeck(response.data);
            } catch (error) {
                console.error('Error fetching recent deck:', error);
            }
        }
    }, [isAuthenticated]);

    const checkAuth = useCallback(async () => {
        const token = localStorage.getItem('token');
        const urlParams = new URLSearchParams(location.search);
        const urlToken = urlParams.get('token');
        
        if (urlToken) {
            localStorage.setItem('token', urlToken);
            navigate(location.pathname, { replace: true });
        }

        if (token || urlToken) {
            try {
                const userData = await api.getUserData();
                setUser(userData.data);
                setIsAuthenticated(true);
                await fetchRecentDeck();
                setCurrentScreen('deck');
            } catch (error) {
                console.error('Error verifying token:', error);
                localStorage.removeItem('token');
            }
        }
        setIsLoading(false);
    }, [location, navigate, fetchRecentDeck]);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    const handleLogin = async (username, password) => {
        try {
            const response = await api.login(username, password);
            localStorage.setItem('token', response.data.access_token);
            const userData = await api.getUserData();
            setUser(userData.data);
            setIsAuthenticated(true);
            await fetchRecentDeck();
        } catch (error) {
            console.error('Login error:', error);
            throw error.msg || 'An unexpected error occurred';
        }
    };

    const handleRegister = async (username, email, password) => {
        try {
            await api.register(username, email, password);
            setRegisteredEmail(email);
            setCurrentScreen('registrationConfirmed');
        } catch (error) {
            console.error('Registration error:', error);
            throw error.msg || 'An unexpected error occurred';
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setCurrentScreen('login');
        setCurrentDeck(null);
        setCurrentTopic(null);
        setUser(null);
        navigate('/');
    };

    // In Snapcards.js, modify handleDeckChange:
    const handleDeckChange = async (newDeck) => {
        if (!newDeck) {
            // No deck provided - fetch most recent (which creates default if none exist)
            await fetchRecentDeck();
        } else {
            setCurrentDeck(newDeck);
            setCurrentScreen('deck');
        }
    };

    const handleSelectTopic = (topicId) => {
        console.log('selectTopic:', topicId)
        setCurrentTopic(topicId);
        setCurrentScreen('topic');
    };

    const handleTopicSelect = async (topicId) => {
        try {
            console.log('onSelectTopic called with ',topicId)
            const response = await api.getTopic(topicId);
            console.log('response from server is ', response.data)
            setCurrentTopic(response.data);
            setCurrentScreen('topic');
        } catch (error) {
            console.error('Error fetching topic:', error);
        }
    };

    const handleStudy = async (deckId, topicId) => {
        if (!topicId) {
            try {
                console.log('starting deck study session with deckId ', deckId)
                const response = await api.getDeck(deckId)
                setCurrentDeck(response.data)
                setCurrentTopic(null)
                setCurrentScreen('study')
            } catch (error) {
                console.error('Error fetching deck:', error)
            }
        } else {
            try {
                console.log('starting topic study session with deckId ', deckId, ' topicId ', topicId)
                const [deckResponse, topicResponse] = await Promise.all([
                    api.getDeck(deckId),
                    api.getTopic(topicId)
                ]);
                setCurrentDeck(deckResponse.data)
                setCurrentTopic(topicResponse.data)
                setCurrentScreen('study')
            } catch (error) {
                console.error('Error fetching deck & topic:', error)
            }
        }
    }

    const handleBackToDeck = useCallback(async (deckId) => {
        try {
            console.log('called handleBackToDeck with deckId ', deckId)
            const response = await api.getDeck(deckId);
            setCurrentTopic(null);
            setCurrentDeck(response.data);
            setCurrentScreen('deck');
        } catch (error) {
            console.error('Error fetching deck: ', error);
        }
    }, []);

    const handleStudyComplete = useCallback(async (deckId) => {
        if (!isFreeReview && deckId) {
            try {
                const response = await api.getDeck(deckId);
                setCurrentTopic(null);
                setCurrentDeck(response.data);
                setCurrentScreen('deck');
            } catch (error) {
                console.error('Error fetching deck: ', error);
            }
        }
        setIsFreeReview(false);
    }, [isFreeReview]);

    const handleGenerateCards = async (formData) => {
        try {
            await api.generateCards(currentDeck.id, formData);
            await fetchRecentDeck();
        } catch (error) {
            console.error('Error generating cards:', error);
            throw error;
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated || !user) {
        switch (currentScreen) {
            case 'login':
                return <LoginScreen onLogin={handleLogin} onRegisterClick={() => setCurrentScreen('register')} />;
            case 'register':
                return <RegisterScreen onRegister={handleRegister} onLoginClick={() => setCurrentScreen('login')} />;
            case 'registrationConfirmed':
                return <RegistrationConfirmedScreen onLoginClick={() => setCurrentScreen('login')} email={registeredEmail} />;
            case 'emailVerification':
                return <EmailVerificationScreen />;
            default:
                return <LoginScreen onLogin={handleLogin} onRegisterClick={() => setCurrentScreen('register')} />;
        }
    }

    return (
        <div>
            <Header 
                user={user} 
                onLogout={handleLogout} 
                onDeckSelect={fetchRecentDeck}
                setCurrentScreen={setCurrentScreen}
            />
            {currentScreen === 'deck' && currentDeck && (
                <DeckScreen 
                    deck={currentDeck}
                    onSelectTopic={handleTopicSelect}
                    onCreateTopic={(name) => api.createTopic(currentDeck.id, name)}
                    onGenerateCards={handleGenerateCards}
                    onStudy={handleStudy}
                    onDeckChange={handleDeckChange}
                />
            )}
            {currentScreen === 'topic' && currentTopic && (
                <TopicScreen 
                    deckId={currentDeck.id}
                    topicId={currentTopic.id}
                    onBack={handleBackToDeck}
                    onUpdateCard={(cardId, question, answer) => api.updateCard(cardId, question, answer)}
                    onDeleteCard={(cardId) => api.deleteCard(cardId)}
                    onCreateCard={(question, answer) => api.createCard(currentTopic.id, question, answer)}
                />
            )}
            {currentScreen === 'study' && currentDeck && (
                <DeckStudyScreen 
                    deck={currentDeck}
                    topic={currentTopic}
                    onBack={handleBackToDeck}
                    user={user}
                    onLogout={handleLogout}
                    onStudyComplete={handleStudyComplete}
                />
            )}
            <Button onClick={() => navigate('/')}>Back to Landing Page</Button>
        </div>
    );
};

export default Snapcards;