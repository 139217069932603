import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { cn } from "../../lib/utils";
import { Upload, X, Link as LinkIcon, FileText } from 'lucide-react';
import PropTypes from 'prop-types';

const SUPPORTED_FILE_TYPES = [
  'text/plain', 
  'application/pdf', 
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/markdown',
  'text/html',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/rtf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/json',
  'application/epub+zip',
  'application/xml',
  'application/x-enex'
];

const SmartInputPanel = forwardRef(({ onInputChange, placeholder, acceptedFileTypes = SUPPORTED_FILE_TYPES, className }, ref) => {
  const [inputType, setInputType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [fileData, setFileData] = useState(null);
  const fileInputRef = useRef(null);

  const detectInputType = useCallback((value) => {
    if (value.startsWith('http://') || value.startsWith('https://')) {
      return 'link';
    } else if (value.length > 0) {
      return 'text';
    }
    return '';
  }, []);

  const clearInput = () => {
    setInputType('');
    setInputValue('');
    setFileData(null);
    onInputChange('', '');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useImperativeHandle(ref, () => ({
    clearInput
  }));

  const handleInputChange = (e) => {
    const value = e.target.value;
    const type = detectInputType(value);
    setInputValue(value);
    setInputType(type);
    setFileData(null);
    onInputChange(value, type);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (SUPPORTED_FILE_TYPES.includes(file.type)) {
        setFileData(file);
        setInputValue(file.name);
        setInputType('file');
        onInputChange(file, 'file');
      } else {
        // Alert the user about unsupported file type
        alert('Unsupported file type. Please upload a supported document type.');
        // Clear the file input
        e.target.value = '';
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (SUPPORTED_FILE_TYPES.includes(file.type)) {
        setFileData(file);
        setInputValue(file.name);
        setInputType('file');
        onInputChange(file, 'file');
      } else {
        // Alert the user about unsupported file type
        alert('Unsupported file type. Please upload a supported document type.');
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const openFileBrowser = () => {
    fileInputRef.current.click();
  };

  return (
    <div 
      className={cn("relative border-2 border-dashed border-gray-300 rounded-lg", className)}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="flex items-center">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-12 flex items-center justify-center">
          {inputType && (
            <div className="text-gray-600">
              {inputType === 'file' && <Upload size={16} />}
              {inputType === 'text' && <FileText size={16} />}
              {inputType === 'link' && <LinkIcon size={16} />}
            </div>
          )}
        </div>
        <div className="relative flex-grow pl-12 pr-3">
          <input
            type="text"
            className="w-full py-3 text-gray-700 rounded outline-none"
            value={inputValue}
            onChange={handleInputChange}
          />
          {!inputValue && (
            <div className="absolute inset-y-0 left-12 flex items-center pointer-events-none">
              <span className="text-gray-500 whitespace-normal">
                {placeholder}{' '}
                <button
                  type="button"
                  className="text-blue-500 font-semibold focus:outline-none pointer-events-auto inline"
                  onClick={openFileBrowser}
                >
                  upload a file.
                </button>
              </span>
            </div>
          )}
        </div>
        {inputValue && (
          <button
            type="button"
            onClick={clearInput}
            className="mr-3 text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>
      <input
        type="file"
        className="hidden"
        onChange={handleFileChange}
        ref={fileInputRef}
        accept={acceptedFileTypes.join(',')}
      />
    </div>
  );
});

SmartInputPanel.displayName = "SmartInputPanel";

SmartInputPanel.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

SmartInputPanel.defaultProps = {
  placeholder: "Type, paste link, drag file, or",
  acceptedFileTypes: SUPPORTED_FILE_TYPES,
};

export { SmartInputPanel };
