import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '../ui/popover';
import { Button } from '../ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { User, LogOut, Key, Trash2 } from 'lucide-react';
import { resetPasswordRequest, deleteAccount } from '../../services/api';

const ProfileSidebar = ({ user, onLogout }) => {
    const handleResetPasswordRequest = async () => {
        if (!user || !user.email) {
            console.error('User email not available');
            return;
        }
        try {
            await resetPasswordRequest(user.email);
            alert('Password reset email sent. Please check your inbox.');
        } catch (error) {
            console.error('Failed to request password reset:', error);
            alert('Failed to request password reset. Please try again later.');
        }
    };

    const handleDeleteAccount = async () => {
        if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
            try {
                await deleteAccount();
                onLogout();
            } catch (error) {
                console.error('Failed to delete account:', error);
                alert('Failed to delete account. Please try again later.');
            }
        }
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="ghost" size="icon" className="rounded-full p-0">
                    <Avatar className="h-10 w-10 border-2 border-gray-300">
                        <AvatarImage src={user?.avatarUrl} alt={user?.username} />
                        <AvatarFallback><User className="h-5 w-5" /></AvatarFallback>
                    </Avatar>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-70" align="end">
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-4">
                        <Avatar className="w-16 h-16 border-2 border-gray-300">
                            <AvatarImage src={user?.avatarUrl} alt={user?.username} />
                            <AvatarFallback><User className="h-8 w-8" /></AvatarFallback>
                        </Avatar>
                        <div>
                            <h2 className="text-xl text-snapcards-blue font-bold">{user?.username}</h2>
                            <p className="text-sm text-gray-600">{user?.email}</p>
                        </div>
                    </div>
                    <Button 
                        onClick={handleResetPasswordRequest} 
                        variant="outline" 
                        className="w-full justify-start"
                    >
                        <Key className="h-4 w-4 mr-2" />
                        Reset Password
                    </Button>
                    <Button 
                        onClick={onLogout} 
                        variant="outline" 
                        className="w-full justify-start"
                    >
                        <LogOut className="h-4 w-4 mr-2" /> 
                        Sign Out
                    </Button>
                    <Button 
                        onClick={handleDeleteAccount} 
                        variant="destructive" 
                        className="w-full justify-start bg-red-600 hover:bg-red-700"
                    >
                        <Trash2 className="h-4 w-4 mr-2" />
                        Delete Account
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
};

export default ProfileSidebar;