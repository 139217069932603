import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Mail } from 'lucide-react';

const RegistrationConfirmedScreen = ({ onLoginClick, email }) => {
    return (
        <div className="min-h-screen bg-snapcards-cream text-black font-['Segoe UI', sans-serif] flex items-center justify-center">
            <Card className="w-full max-w-md bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
                <CardHeader>
                    <CardTitle className="text-3xl font-bold text-center text-black">Registration Successful!</CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                    <div className="flex justify-center mb-6">
                        <Mail className="h-16 w-16 text-snapcards-blue" />
                    </div>
                    <p className="text-gray-600 mb-6">
                        We've sent a verification email to <strong>{email}</strong>. 
                        Please check your inbox and click the verification link to activate your account.
                    </p>
                    <p className="text-gray-600 mb-6">
                        If you don't see the email, please check your spam folder.
                    </p>
                    <Button onClick={onLoginClick} className="w-full bg-snapcards-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                        Go to Login
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};

export default RegistrationConfirmedScreen;