import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "./dialog";
import { Button } from "./button";

const DeleteConfirmationDialog = ({ 
    isOpen, 
    onClose, 
    onConfirm, 
    title = "Confirm Deletion",
    description = "Are you sure you want to delete this item? This action cannot be undone.",
}) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px] space-y-2">
                <DialogHeader className="pb-0">
                    <DialogTitle className="font-bold text-xl">
                        {title}
                    </DialogTitle>
                </DialogHeader>
                <div className="py-0">
                    <p className="text-gray-600">{description}</p>
                </div>
                <DialogFooter className="pt-0">
                    <Button type="button" variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button 
                        type="button"
                        onClick={onConfirm}
                        className="bg-snapcards-blue text-white hover:bg-blue-700"
                    >
                        Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;