import React, { useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { UserPlus, CheckCircle, Loader2, X } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import { Checkbox } from '../ui/checkbox';
import { googleLogin, checkUsername, checkEmail } from '../../services/api';
import { Link } from 'react-router-dom';

const RegisterScreen = ({ onRegister, onLoginClick }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [feedbackType, setFeedbackType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [fieldStates, setFieldStates] = useState({
        username: { isChecking: false, isValid: null, error: null },
        email: { isChecking: false, isValid: null, error: null },
        password: { isChecking: false, isValid: null, error: null }
    });

    const clearFeedback = () => {
        setFeedbackMessage('');
        setFeedbackType('');
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must contain at least one uppercase letter");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Password must contain at least one lowercase letter");
        }
        if (!/\d/.test(password)) {
            errors.push("Password must contain at least one digit");
        }
        return errors;
    };

    const debouncedValidate = useDebouncedCallback(async (field, value) => {
        setFieldStates(prev => ({ ...prev, [field]: { ...prev[field], isChecking: true } }));
        
        let isValid = false;
        let error = null;

        switch (field) {
            case 'username':
                if (value.length > 0) {
                    try {
                        const response = await checkUsername(value);
                        isValid = response.data.available;
                        error = isValid ? null : "Username is already taken";
                    } catch (err) {
                        console.error('Error checking username:', err);
                        error = "Error checking username availability";
                    }
                }
                break;
            case 'email':
                isValid = validateEmail(value);
                if (isValid) {
                    try {
                        const response = await checkEmail(value);
                        isValid = response.data.available;
                        error = isValid ? null : "Email is already registered";
                    } catch (err) {
                        console.error('Error checking email:', err);
                        error = "Error checking email availability";
                    }
                } else {
                    error = "Invalid email format";
                }
                break;
            case 'password':
                const passwordErrors = validatePassword(value);
                isValid = passwordErrors.length === 0;
                error = isValid ? null : passwordErrors.join(", ");
                break;
        }

        setFieldStates(prev => ({
            ...prev,
            [field]: { isChecking: false, isValid, error }
        }));
    }, 1000);

    useEffect(() => {
        if (username) debouncedValidate('username', username);
        return () => debouncedValidate.cancel();
    }, [username, debouncedValidate]);

    useEffect(() => {
        if (email) debouncedValidate('email', email);
        return () => debouncedValidate.cancel();
    }, [email, debouncedValidate]);

    useEffect(() => {
        if (password) debouncedValidate('password', password);
        return () => debouncedValidate.cancel();
    }, [password, debouncedValidate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearFeedback();
        if (!fieldStates.email.isValid || !fieldStates.password.isValid || !fieldStates.username.isValid || !acceptedTerms) {
            setFeedbackMessage('Please correct the errors and accept the Terms of Service before submitting');
            setFeedbackType('error');
            return;
        }
        setIsLoading(true);
        try {
            await onRegister(username, email, password);
            // Successful registration will be handled by the parent component
        } catch (error) {
            console.error('Registration error:', error);
            setFeedbackMessage(error.toString());
            setFeedbackType('error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSignUp = () => {
        googleLogin();
    };

    const handleTermsChange = (checked) => {
        setAcceptedTerms(checked);
    };

    const renderInputWithValidation = (field, type, placeholder, value, onChange) => {
        const { isChecking, isValid, error } = fieldStates[field];
        return (
            <div className="relative mb-4">
                <div className="relative">
                    <Input
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                            clearFeedback();
                        }}
                        required
                        className={`pr-10 ${
                            error && value !== '' ? 'border-red-500' : 
                            isValid && value !== '' ? 'border-green-500' : 
                            'border-gray-300'
                        }`}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        {isChecking && value !== '' && <Loader2 className="text-gray-400 animate-spin" size={20} />}
                        {!isChecking && isValid && value !== '' && <CheckCircle className="text-green-500" size={20} />}
                        {!isChecking && error && value !== '' && <X className="text-red-500" size={20} />}
                    </div>
                </div>
                {error && value !== '' && <p className="mt-1 text-xs text-red-500">{error}</p>}
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-snapcards-cream text-black font-['Segoe UI', sans-serif] flex items-center justify-center">
            <Card className="w-full max-w-md bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
                <CardHeader>
                    <CardTitle className="text-3xl font-bold text-center text-black">Register</CardTitle>
                </CardHeader>
                <CardContent>
                    {feedbackMessage && (
                        <Alert variant={feedbackType === 'error' ? 'destructive' : 'default'} className="mb-6">
                            <AlertDescription>{feedbackMessage}</AlertDescription>
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {renderInputWithValidation('username', 'text', 'Username', username, setUsername)}
                        {renderInputWithValidation('email', 'email', 'Email', email, setEmail)}
                        {renderInputWithValidation('password', 'password', 'Password', password, setPassword)}
                        
                        <div className="flex items-center space-x-2 justify-center">
                            <Checkbox
                                id="terms"
                                checked={acceptedTerms}
                                onCheckedChange={handleTermsChange}
                                className="border-gray-300 text-snapcards-blue focus:ring-snapcards-blue"
                            />
                            <label
                                htmlFor="terms"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
                            >
                                I accept the{' '}
                                <Link to="/terms" className="text-snapcards-blue hover:underline">
                                    Terms of Service and Privacy Policy
                                </Link>
                            </label>
                        </div>

                        <Button 
                            type="submit" 
                            className="w-full bg-snapcards-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                            disabled={isLoading || !fieldStates.username.isValid || !fieldStates.email.isValid || !fieldStates.password.isValid || !acceptedTerms}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Registering...
                                </>
                            ) : (
                                <>
                                    <UserPlus className="h-5 w-5 mr-2" /> 
                                    Register
                                </>
                            )}
                        </Button>
                    </form>
                    <div className="mt-8 relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">Or sign up with</span>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Button
                            onClick={handleGoogleSignUp}
                            className="w-full bg-white hover:bg-gray-50 text-gray-900 border border-gray-300 font-bold py-2 px-4 rounded flex items-center justify-center space-x-2 transition-colors duration-300"
                        >
                            <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                            </svg>
                            <span>Sign up with Google</span>
                        </Button>
                    </div>
                    <p className="text-center mt-8 text-sm text-gray-600">
                        Already have an account?{' '}
                        <Button variant="link" onClick={onLoginClick} className="p-0 text-snapcards-blue hover:text-blue-700 font-bold">
                            Login
                        </Button>
                    </p>
                </CardContent>
            </Card>
        </div>
    );
};

export default RegisterScreen;