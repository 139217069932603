import React, { useState, useRef } from 'react';
import { Menu, Book, Plus, Edit, Trash2 } from 'lucide-react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { ScrollArea } from "../ui/scroll-area";
import DeleteConfirmationDialog from '../ui/delete-confirmation-dialog';

const AddDeckDialog = ({ isOpen, onClose, onCreateDeck }) => {
  const [newDeckName, setNewDeckName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newDeckName.trim()) {
      onCreateDeck(newDeckName.trim());
      setNewDeckName('');
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Deck</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4 pt-4">
          <Input
            id="name"
            value={newDeckName}
            onChange={(e) => setNewDeckName(e.target.value)}
            className="w-full"
            placeholder="Enter deck name"
          />
          <DialogFooter>
            <Button type="button" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" className="bg-snapcards-blue text-white hover:bg-blue-700">
              Create Deck
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const DeckSelectionMenu = ({ decks, currentDeckId, onDeckSelect, onCreateDeck, onUpdateDeck, onDeleteDeck }) => {
  const [isAddDeckDialogOpen, setIsAddDeckDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDecks, setEditedDecks] = useState({});
  const dropdownRef = useRef(null);
  const updatingRef = useRef(false);
  const [deckToDelete, setDeckToDelete] = useState(null);

  const handleOpenAddDeckDialog = () => {
    setIsAddDeckDialogOpen(true);
  };

  const handleCloseAddDeckDialog = () => {
    setIsAddDeckDialogOpen(false);
  };

  const saveChanges = async () => {
    if (updatingRef.current) return;
    updatingRef.current = true;
    setIsEditing(false);

    try {
      for (const [id, name] of Object.entries(editedDecks)) {
        if (name && name.trim() !== '' && name.trim() !== decks.find(d => d.id === id)?.name) {
          await onUpdateDeck(id, name.trim());
        }
      }
    } finally {
      setEditedDecks({});
      updatingRef.current = false;
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      saveChanges();
    } else {
      setIsEditing(true);
    }
  };

  const handleDeckNameChange = (id, newName) => {
    setEditedDecks({ ...editedDecks, [id]: newName });
  };

  const handleDeleteDeck = (e, deck) => {
    e.stopPropagation();
    setDeckToDelete(deck);
  };

  const confirmDeleteDeck = async () => {
    if (deckToDelete) {
      await onDeleteDeck(deckToDelete.id);
      setDeckToDelete(null);
    }
  };

  const handleKeyDown = async (e, deckId) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      
      if (updatingRef.current) return;
      updatingRef.current = true;

      const newName = editedDecks[deckId]?.trim();
      const currentName = decks.find(d => d.id === deckId)?.name;
      
      if (newName && newName !== currentName) {
        setIsEditing(false);
        setEditedDecks(prev => {
          const next = { ...prev };
          delete next[deckId];
          return next;
        });
        
        try {
          await onUpdateDeck(deckId, newName);
        } finally {
          updatingRef.current = false;
        }
      }
    }
  };

  const handleOutsideClick = () => {
    if (isEditing) {
      saveChanges();
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="rounded-full p-0">
            <Menu className="h-5 w-5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent 
          ref={dropdownRef}
          className="w-70 bg-white border border-gray-200 shadow-md" 
          align="start"
          onInteractOutside={handleOutsideClick}
          onPointerDownOutside={e => {
            if (isEditing) {
              e.preventDefault();
            }
          }}
        >
          <div className="flex flex-col space-y-4 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <Book className="h-8 w-8 text-snapcards-blue" />
                <div>
                  <DropdownMenuLabel className="text-xl text-snapcards-blue font-bold p-0">Your Decks</DropdownMenuLabel>
                  <p className="text-sm text-gray-600">{decks.length} decks available</p>
                </div>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleEditToggle}
                className="text-snapcards-blue hover:bg-gray-100"
              >
                <Edit className="h-4 w-4 mr-2" />
                {isEditing ? 'Save' : 'Edit'}
              </Button>
            </div>
            <DropdownMenuSeparator className="bg-gray-200" />
            <ScrollArea className="h-[200px] w-full">
              <div className="pr-4">
                {decks.map((deck) => (
                  <DropdownMenuItem
                    key={deck.id}
                    onSelect={(e) => {
                      if (!isEditing) {
                        onDeckSelect(deck.id);
                      }
                    }}
                    className={`flex items-center space-x-2 p-2 rounded-md ${
                      deck.id === currentDeckId 
                        ? 'bg-gray-100 text-snapcards-blue' 
                        : 'text-gray-700 hover:bg-gray-50 hover:text-snapcards-blue'
                    }`}
                  >
                    <Book className="h-4 w-4" />
                    {isEditing ? (
                      <Input
                        value={editedDecks[deck.id] || deck.name}
                        onChange={(e) => handleDeckNameChange(deck.id, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, deck.id)}
                        onClick={(e) => e.stopPropagation()}
                        className="flex-grow"
                      />
                    ) : (
                      <span>{deck.name || 'Unnamed Deck'}</span>
                    )}
                    {isEditing && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={(e) => handleDeleteDeck(e, deck)}
                        className="ml-auto text-red-500 hover:text-red-700"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    )}
                  </DropdownMenuItem>
                ))}
              </div>
            </ScrollArea>
            <DropdownMenuSeparator className="bg-gray-200" />
            <DropdownMenuItem 
              onSelect={handleOpenAddDeckDialog}
              className="flex items-center space-x-2 p-2 rounded-md text-snapcards-blue hover:bg-gray-50"
            >
              <Plus className="h-4 w-4" />
              <span>Add New Deck</span>
            </DropdownMenuItem>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <AddDeckDialog
        isOpen={isAddDeckDialogOpen}
        onClose={handleCloseAddDeckDialog}
        onCreateDeck={onCreateDeck}
      />
      <DeleteConfirmationDialog
        isOpen={!!deckToDelete}
        onClose={() => setDeckToDelete(null)}
        onConfirm={confirmDeleteDeck}
        title="Delete Deck"
        description={`Are you sure you want to delete "${deckToDelete?.name}"? This action cannot be undone.`}
      />
    </>
  );
};

export default DeckSelectionMenu;