export const HEADER_CONFIG = {
    title: "Snapcards",
    logo: {
      src: "/icon.png",
      alt: "Snapcards Logo"
    },
    navigation: [
      { label: "About Us", path: "/about" },
      { label: "Research", path: "/research" },
      { label: "Pricing", path: "/pricing" },
      { label: "Login", path: "/app" }
    ]
  };