import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const DIFFICULTY_LEVELS = {
  EASY: 2.5,
  MEDIUM: 2,
  HARD: 1.5,
};

export const calculateNextReview = (difficulty, lastInterval) => {
  const interval = lastInterval * DIFFICULTY_LEVELS[difficulty];
  return new Date(Date.now() + interval * 24 * 60 * 60 * 1000);
};