import { HEADER_CONFIG } from './headerConfig';

export const ABOUT_PAGE_CONTENT = {
  header: HEADER_CONFIG,
  hero: {
    title: "Our Mission",
    description: "At Snapcards, we're dedicated to revolutionizing the way people learn and retain information. Our mission is to make learning efficient, engaging, and accessible to everyone.",
  },
  values: {
    title: "Our Core Values",
    items: [
      {
        icon: "Users",
        title: "Community-Driven",
        description: "We believe in the power of collaborative learning. Our community of avid learners shares collective knowledge and ideas, fostering a productive and cooperative environment. We continuously develop community-driven features and listen to our users' feedback to enhance mutual learning experiences.",
      },
      {
        icon: "BookOpen",
        title: "Research-Focused",
        description: "Our primary commitment is to improve learning techniques using peer-reviewed scientific research. We integrate the latest discoveries in knowledge acquisition and spaced repetition to create a truly effective tool for avid learners. By implementing evidence-based practices, we ensure our product evolves with the science of learning.",
      },
      {
        icon: "Trophy",
        title: "Excellence",
        description: "We strive for excellence in every aspect of our work, from technology to customer support. Our dedication to quality is reflected in our attention to detail across all our products and services. We actively seek and value user input to ensure we're providing the best possible experience for our community.",
      },
      {
        icon: "Sparkles",
        title: "Innovation",
        description: "To remain at the forefront of educational technology, we embrace constant innovation. We regularly update our platform with new features based on emerging technologies and community feedback. Through innovation, we evolve to become the ultimate tool for passionate learners worldwide.",
      },
    ],
  },
  story: {
    title: "Our Vision",
    content: "Learning is a fundamental human skill that empowers professionals across all fields, from medicine to law to technology. The boundaries of human achievement are limited only by our knowledge, and the acquisition of knowledge is bound by our ability to learn effectively and retain information. At Snapcards, we aim to push these boundaries. Leveraging scientific discoveries in spaced repetition, we've created a versatile, highly effective tool for learners worldwide. Together with our growing community, we envision a world where optimal knowledge acquisition is possible for everyone, everywhere.",
  },
  cta: {
    title: "Join The Learning Revolution",
    description: "Experience the power of Snapcards for yourself. Start your learning journey today!",
    button: {
      label: "Get Started",
      path: "/register",
    },
  },
  footer: {
    companyInfo: {
      name: "Snapcards",
      slogan: "Innovating the Future of Learning"
    },
    quickLinks: [
      { label: "About Us", path: "/about" },
      { label: "Research", path: "/research" },
      { label: "Pricing", path: "/pricing" },
      { label: "Contact", path: "/contact" }
    ],
    legal: [
      { label: "Terms of Service", path: "/terms" },
      { label: "Privacy Policy", path: "/privacy" }
    ],
    socialMedia: [
      { platform: "Facebook", url: "#" },
      { platform: "Twitter", url: "#" },
      { platform: "Instagram", url: "#" },
      { platform: "LinkedIn", url: "#" }
    ],
    copyright: "© 2024 Snapcards. All rights reserved."
  }
};