export const FOOTER_CONFIG = {
    companyInfo: {
      name: "Snapcards",
      slogan: "Revolutionizing the way you learn"
    },
    quickLinks: [
      { label: "Home", path: "/" },
      { label: "About", path: "/about" },
      { label: "Research", path: "/research" },
      { label: "Pricing", path: "/pricing" }
    ],
    legal: [
      { label: "Terms of Service and Privacy Policy", path: "/terms" },
    ],
    socialMedia: [
      { platform: "Twitter", url: "https://twitter.com/snapcards" },
      { platform: "Facebook", url: "https://facebook.com/snapcards" },
      { platform: "Instagram", url: "https://instagram.com/snapcards" }
    ],
    copyright: "© 2024 Snapcards. All rights reserved."
  };