import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { CheckCircle, XCircle, Loader } from 'lucide-react';
import * as api from '../../services/api';

const EmailVerificationScreen = () => {
  const [status, setStatus] = useState('verifying');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifyEmail = async () => {
      if (verificationAttempted.current) return;
      verificationAttempted.current = true;

      try {
        const response = await api.verifyEmail(token);
        console.log('Verification response:', response);
        if (response.data && response.data.access_token) {
          setStatus('success');
          setMessage('Your email has been successfully verified. Redirecting you to the app...');
          localStorage.setItem('token', response.data.access_token);
          setTimeout(() => navigate('/app'), 3000);
        } else if (response.data && response.data.msg === "Email already verified.") {
          setStatus('success');
          setMessage('Your email was already verified. You will be redirected to the login page.');
          setTimeout(() => navigate('/app'), 3000);
        } else {
          setStatus('error');
          setMessage('An unexpected error occurred. Please try again.');
        }
      } catch (error) {
        console.error('Email verification error:', error);
        setStatus('error');
        setMessage(error.msg || 'An error occurred during email verification. Please try again.');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  const renderContent = () => {
    switch (status) {
      case 'verifying':
        return (
          <>
            <div className="flex justify-center">
              <Loader className="h-12 w-12 text-snapcards-blue animate-spin" />
            </div>
            <CardTitle className="mt-4">Verifying Your Email</CardTitle>
            <p className="text-gray-600 mt-2">Please wait while we verify your email address...</p>
          </>
        );
      case 'success':
        return (
          <>
            <div className="flex justify-center">
              <CheckCircle className="h-12 w-12 text-green-500" />
            </div>
            <CardTitle className="mt-4">Email Verified</CardTitle>
            <p className="text-gray-600 mt-2">{message}</p>
          </>
        );
      case 'error':
        return (
          <>
            <div className="flex justify-center">
              <XCircle className="h-12 w-12 text-red-500" />
            </div>
            <CardTitle className="mt-4">Verification Failed</CardTitle>
            <p className="text-gray-600 mt-2">{message}</p>
          </>
        );
    }
  };

  return (
    <div className="min-h-screen bg-snapcards-cream flex items-center justify-center p-4">
      <Card className="w-full max-w-md bg-white shadow-lg">
        <CardHeader className="text-center">
          <CardTitle className="text-2xl font-bold text-black">Email Verification</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          {renderContent()}
        </CardContent>
      </Card>
    </div>
  );
};

export default EmailVerificationScreen;